@import './SCSS/mixins/tenant-variables.scss';

//ALL TENANT COMMON CSS
#tenant-mazoola,
#tenant-q2,
#tenant-college {
    label {
        @media (max-width:1024px) {
            font-size: var(--font-13);
            margin-bottom: 0px;
        }
    }

    input.form-control,
    select {
        @media (max-width:1024px) {
            font-size: var(--font-13);

            &::placeholder {
                font-size: var(--font-13);
            }
        }
    }

    .linkBtn {
        @media (max-width:1024px) {
            font-size: var(--font-13);
        }
    }

    .dashboard-wrapper {
        .dashboard-family-tabs {
            .nav-tabs {
                li {
                    a {
                        &.nav-link {
                            @media (max-width:1024px) {
                                @include tabs-font-ipad();

                                &.active {
                                    &::after {
                                        bottom: 1px;
                                    }
                                }
                            }

                        }
                    }

                    @media (max-width:1024px) {
                        margin-bottom: 5px;
                        margin-right: 0.938rem;
                    }
                }

                &.myFamily-navtabs {
                    @media (max-width:575px) {
                        padding-top: 0;
                    }
                }
            }

            .tab-content {
                .chore-sticky-row {
                    @media (max-width:575px) {
                        top: 103px;
                    }
                }

                .search-chore {
                    .mobile-chore-btn {
                        @media (max-width:575px) {
                            padding: 10px 12px !important;
                            min-width: auto !important;
                        }
                    }
                }

                .bank-connection-expired {
                    .icon-title {
                        .title-desc {
                            h3 {
                                span {
                                    svg {
                                        @media (max-width:1024px) {
                                            width: 18px;
                                        }
                                    }

                                    @media (max-width:1024px) {
                                        right: -10px;
                                        top: -2px;
                                    }
                                }

                                @media (max-width:1024px) {
                                    font-size: var(--font-13);
                                    margin-bottom: 4px;
                                }
                            }

                            p {
                                @media (max-width:1024px) {
                                    font-size: var(--font-13);
                                }
                            }
                        }

                        @media (max-width:1024px) {
                            padding: 9px 1.250rem;
                        }
                    }
                }

                @media (max-width:1024px) {
                    margin-top: 0.938rem;
                }
            }

            .my-family-header-group {
                .pgHeading {
                    @media (max-width:1024px) {
                        height: 41px;
                    }
                }

                @media (max-width:1024px) {
                    margin-top: -59px;
                }

                @media (max-width:575px) {
                    margin-top: -31px;
                    padding-top: 16px;
                }
            }
        }

        .dashboard-right-article {

            @media (max-width:1024px) and (min-width:575px) {
                margin-top: 6px;
            }

            .dashboard-heading {
                // @media (max-width:1024px) {
                //     @include page-title-ipad();
                // }

                .heading-right-icon-list {
                    // @media (max-width:575px) {
                    //     margin-top: -5px;
                    // }
                }
            }

            .dashboard-tile {
                .dashboard-tile-container {
                    .dashboard-tile-content {

                        .tile-childName.children,
                        .tile-childName.chore {
                            @media (max-width:1024px) {
                                font-size: var(--font-14);
                            }

                        }
                    }
                }
            }

            .add-chore-wrapper1 {
                &.level-2 {
                    h1 {
                        @media (max-width:1024px) {
                            @include page-title-ipad();
                        }

                    }

                    .routeBack-abs {
                        @media (max-width:575px) {
                            top: 15px;
                        }
                    }

                    .title {
                        @media (max-width:575px) {
                            padding-bottom: 0;
                        }
                    }
                }

                .title-bar {
                    span.choreMis{
                        @media (max-width:575px){
                            margin-top: -3px;
                        }
                    }

                    .routeBack-flat{
                        @media (max-width:575px){
                            margin-top: 0;
                        }
                    }

                    @media (max-width:575px){
                        padding-top:16px;
                    }
                }

                .child-list {
                    .child-listing {
                        ul {
                            li {
                                .child-content {
                                    .img-title {
                                        @media (max-width:1024px) {
                                            width: 38px;
                                            height: 38px;
                                            min-width: 38px;
                                            min-height: 38px;
                                            line-height: 32px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    h3 {
                        @media (max-width:1024px) {
                            font-size: var(--font-14);
                        }
                    }
                }

                .form-group {
                    @media (max-width:1024px) {
                        margin-bottom: 30px;
                    }
                }

                .description {
                    label {
                        @media (max-width:1024px) {
                            font-size: var(--font-14);
                        }
                    }

                    @media (max-width:1024px) {
                        margin: 30px auto 30px auto;
                    }
                }

                .rewards {
                    label {
                        @media (max-width:1024px) {
                            font-size: var(--font-14);
                        }
                    }
                }

                .time {
                    h3 {
                        @media (max-width:1024px) {
                            font-size: var(--font-14);
                        }
                    }

                    ul {
                        li {
                            @media (max-width:1024px) {
                                font-size: var(--font-13);
                            }
                        }
                    }
                }

                .viewChildUpdation {
                    .singleChildAuth {
                        .weeksName {
                            ul {
                                li {
                                    @media (max-width:1024px) {
                                        width: 42px;
                                        height: 44px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .child-detail-wrapper {
                .child-detail-breadcrumb {
                    .routeBack-abs{
                        @media (max-width:575px){
                            top:25px;
                        }
                    }
                    .rightBtns {
                        @media (max-width:575px) {
                            top: 23px;
                        }
                    }

                    @media (max-width:575px){
                        padding-top: 16px;
                    }
                }

                .digital-wallet-childDtl {
                    .wallet-amount {
                        .total-amount {
                            @media (max-width:1024px) {
                                font-size: var(--font-30);
                                line-height: 40px;
                            }
                        }
                    }
                }

                .digital-wallet-childDtl,
                .financial-targets {
                    h3 {
                        @media (max-width:1024px) {
                            font-size: var(--font-16);
                        }

                    }
                }

                .chores-wrapper {
                    .chores-header-container {
                        h3 {
                            @media (max-width:1024px) {
                                font-size: var(--font-16);
                            }
                        }
                    }

                    @media (max-width:1024px) {
                        margin-bottom: 30px;
                    }
                }

                .chores-blank-wrapper {
                    h3 {
                        @media (max-width:1024px) {
                            font-size: var(--font-16);
                        }
                    }
                }

                .goals-blank-wrapper,
                .goals-wrapper {
                    h3 {
                        @media (max-width:1024px) {
                            font-size: var(--font-16);
                        }

                    }
                }

                .goals-wrapper {
                    .goals-progress-container {
                        .goals-progress-single {
                            .goal-avatar {
                                @media (max-width:1024px) {
                                    width: 48px;
                                    height: 48px;
                                    min-height: 48px;
                                    min-width: 48px;
                                }

                                @media (max-width:1024px) {
                                    margin-bottom: 0.938rem;
                                }
                            }
                        }
                    }
                }
            }

            .btn-gradient-outline,
            .btn-gray,
            .btn-gradient,
            .btn-white {
                @media (max-width:1024px) {
                    padding: 7px 1.250rem;
                    min-width: 100px;
                }

            }

            .familyInvitation-tile {
                h1 {
                    .routeBack-abs {
                        @media (max-width:575px) {
                            top: 15px;
                        }
                    }

                    @media (max-width:1024px) {
                        @include page-title-ipad();
                    }
                }

                .invitationTile-wrapper {
                    .invitationTile-box {
                        h3 {
                            @media (max-width:1024px) {
                                font-size: var(--font-16);
                                line-height: 1.250rem;
                            }
                        }

                        p {
                            @media (max-width:1024px) {
                                font-size: var(--font-13);
                                line-height: 18px;
                            }
                        }
                    }
                }
            }

            .inviteChild,
            .inviteAdult {
                h1 {
                    @media (max-width:1024px) {
                        @include page-title-ipad();
                    }
                }

                .routeBack-abs {
                    @media (max-width:575px) {
                        top: 13px;
                    }
                }

                .invite_child-title {
                    @media (max-width:1024px) {
                        font-size: var(--font-16);
                    }
                }
            }

            .scheduleTransfer {
                .scheduledTransfers {
                    .singleSchedule {
                        .icn {
                            @media (max-width:1024px) {
                                width: 38px;
                                height: 38px;
                                min-width: 38px;
                                min-height: 38px;
                            }
                        }

                        .transferDtl {
                            h5 {
                                @media (max-width:1024px) {
                                    font-size: var(--font-13);
                                    line-height: 1.250rem;
                                }
                            }
                        }

                        .amount {
                            @media (max-width:1024px) {
                                font-size: var(--font-13);
                                line-height: 1.250rem;
                            }
                        }
                    }
                }

                .routeBack-flat {
                    @media (max-width:575px) {
                        top: 14px;
                    }
                }
            }

            .receiptsWrapper {
                .receiptSingle {

                    @media (max-width:1024px) {
                        padding: 0.625rem 0px 0.625rem 2px;
                        font-size: var(--font-13);
                        line-height: 1.250rem;
                    }

                    @media (max-width:575px) {
                        padding: 0.625rem 0.938rem;
                        font-size: var(--font-13);
                    }
                }

                .routeBack-flat {
                    @media (max-width:575px) {
                        top: 14px;
                    }
                }
            }

            .statementWrapper {
                .routeBack-flat {
                    @media (max-width:575px) {
                        top: 14px;
                    }
                }
            }
        }

        .parent-left-sidebar {
            ul {
                li {
                    font-size: var(--font-14);
                    a {
                        svg {
                            @media (max-width:1024px) {
                                width: 22px;
                            }
                        }
                    }
                }
            }
        }

        .no-data-found {
            .no-data-title {
                @media (max-width:1024px) {
                    font-weight: 500;
                }
            }

            .iconbox {
                img {
                    @media (max-width:1024px) {
                        width: 45px;
                    }
                }

                @media (max-width:1024px) {
                    width: 110px;
                    height: 110px;
                    border-radius: 55px;
                }
            }
        }
    }

    .signupLanding {
        .signupLandingWrapper {

            &.login,
            &.registration {
                .signupLandingtile {
                    h3 {
                        font-size: var(--font-30);
                        line-height: 48px;
                    }
                }
            }
        }
    }

    .page-parent-child-detail {
        @media (max-width:1024px) {
            margin-top: 0 !important;
        }
    }

    .page-parent-shopping {

        .shop-wrapper,
        .child-shop-wrapper {
            .tiles-cards-group {
                .dashboard-children-card {
                    .singleRetailer {
                        .card-inner {
                            .tile-content {
                                h4 {
                                    @media (max-width:1024px) {
                                        font-size: var(--font-14);
                                        line-height: 1.250rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .shop-dash-merchants {
                .search-box-row {
                    @media (max-width:575px) {
                        top: 56px
                    }
                }

                .approvedRetailers {
                    h3 {
                        @media (max-width:575px) {
                            font-size: var(--font-13);
                            line-height: 1.25rem;
                            margin-bottom: 0.625rem;
                        }
                    }
                }

                .additionalRetailers {
                    h3 {
                        @media (max-width:575px) {
                            font-size: var(--font-13);
                            line-height: 1.25rem;
                            margin-bottom: 0.625rem;
                        }
                    }

                    .singleRetailer {
                        h4 {
                            @media (max-width:1024px) {
                                font-size: var(--font-13);
                                line-height: 17px;
                            }
                        }

                        span {
                            img {
                                @media (max-width:1024px) {
                                    width: 38px;
                                    max-width: 38px;
                                    min-width: 38px;
                                }
                            }
                        }
                    }
                }
            }

            .dashboard-family-tabs {
                .nav-tabs {
                    padding-top: 12px;
                }
            }
        }



    }

    .page-parent-child-setting {
        .nav-tabs {
            @media (max-width:1024px) {
                margin-top: -5px;
            }
        }

        .profile-tab {
            .editUserAvatar {
                .userIconDisplayBox {
                    @media (max-width:1024px) {
                        width: 80px;
                        height: 80px;
                    }
                }

                @media (max-width:1024px) {
                    margin-top: 50px;
                }
            }
        }

        .peers-tab {

            .requests,
            .peers {
                h1 {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }
        }

        .spending-tab {
            .single-spending-tab {
                h1 {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                        margin-top: 0.938rem;
                    }
                }

                .spending-row {
                    h3 {
                        .inputGrp.ft {
                            input {
                                @media (max-width:1024px) {
                                    font-size: var(--font-13);
                                    padding-bottom: 6px;
                                }
                            }
                        }

                        @media (max-width:1024px) {
                            font-size: var(--font-13);
                            line-height: 23px;
                        }

                    }

                    @media (max-width:1024px) {
                        padding: 0.625rem 0px;
                    }

                    @media (max-width:575px) {
                        padding: 0.625rem 15px;
                    }
                }
            }

            &.ft {
                .single-spending-tab {
                    .spending-row {
                        @media (max-width:575px) {
                            padding: 0.625rem 0px;
                        }
                    }
                }
            }
        }

        .children-setting-wrapper {
            .dashboard-family-tabs {
                .pgHeading {
                    .child-setting-top-mobile-links {
                        @media (max-width:575px) {
                            top: 10px;
                        }
                    }

                    .shield-icon {
                        @media (max-width:575px) {
                            top: 13px;
                        }
                    }
                }

            }
        }
    }

    .timelines-listing-wrapper {
        .search-timeline {
            .child-listing {
                .mobile-activity-label {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }

            @media (max-width:575px) {
                padding-top: 10px;
                top: 40px;
            }
        }
    }

    .page-parent-setting-dashboard {
        .children-familytab {
            .add-invite-btn {
                @media (max-width:1024px) {
                    // position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 9;
                }
            }

            .childrenPanel,
            .adultsPanel {
                h1 {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }

            @media (max-width:1024px) {
                padding-top: 0.938rem;
                position: relative;
            }

        }
    }

    .page-parent-setting-dashboard {
        .banking-tab {
            .bank-available-flow {
                .banks-list {
                    .bank-detail {
                        .bank-icon {
                            @media (max-width:1024px) {
                                width: 35px;
                                min-width: 35px;
                            }
                        }

                        .bank-name {
                            h3 {
                                @media (max-width:1024px) {
                                    font-size: var(--font-14);
                                }
                            }

                        }


                        @media (max-width:1024px) {
                            width: 48%;
                        }
                    }
                }

                .bankingNoteBottom {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }
        }

        .parentSetting-notifytab {
            .single-spending-tab {
                h1 {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                        margin-top: 0.938rem;
                    }
                }

                .spending-row {
                    @media (max-width:1024px) {
                        padding: 0.625rem 0px;
                    }

                    h3 {
                        @media (max-width:1024px) {
                            font-size: var(--font-13);
                            line-height: 23px;
                        }

                    }
                }
            }
        }

        .parentSetting-legaltab {
            #accordion {
                .card {
                    .card-header {
                        h5 {
                            a {
                                @media (max-width:1024px) {
                                    font-size: var(--font-13);
                                    line-height: 23px;
                                }
                            }
                        }

                        @media (max-width:1024px) {
                            padding: 0.625rem 0px;
                        }

                        @media (max-width:575px) {
                            padding: 0.625rem 0.938rem;
                        }
                    }
                }
            }
        }

        .refer-friend-container {

            .refer-earn,
            .refer-rocks,
            .refer-help {
                @media (max-width:1024px) {
                    font-size: var(--font-18);
                }
            }

            .refer-desc {
                @media (max-width:1024px) {
                    font-size: var(--font-13);
                }
            }

            .refer-simple {
                @media (max-width:1024px) {
                    font-size: var(--font-18);
                }
            }

            ol {
                li {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }

            .refer-btn {
                a {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }
        }
    }

    .parental-superpower-screen {
        .superpower-links {
            .superpower-link-single {

                span {
                    svg {
                        @media (max-width:1024px) {
                            width: 0.875rem;
                        }
                    }
                }

                @media (max-width:1024px) {
                    font-size: var(--font-13);
                    line-height: 1.250rem;
                    padding: 0.625rem 0px;
                }
            }
        }

        .routeBack-flat {
            @media (max-width:575px) {
                top: 14px;
            }
        }
    }

    .investment-wrapper {
        .setting-investment {
            .pgHeading {

                @media (max-width:1024px) {
                    @include page-title-ipad();
                    margin-bottom: 0.938rem
                }
            }
        }
    }

    .parent-setting-wrapper {
        .pgHeading {
            .routeBack-invest-abs {
                @media (max-width:575px) {
                    top: 0.938rem;
                }
            }

            @media (max-width:1024px) {
                @include page-title-ipad();
            }
        }

        .parentSetting-investmentTab {
            .redirection-message {
                @media (max-width:1024px) {
                    font-size: var(--font-16);
                    line-height: 1.250rem;
                }
            }
        }

        .banking-tab {
            .connecting-bank-msg {
                .wrapper {
                    h1 {
                        @media (max-width:1024px) {
                            font-size: var(--font-16);
                            line-height: 1.250rem;
                        }
                    }

                    h4 {
                        @media (max-width:1024px) {
                            font-size: var(--font-13);
                            line-height: 1.250rem;
                        }
                    }
                }
            }
        }

        .top-buttons-group {
            @media (max-width:575px) {
                top: 14px;
            }
        }
    }

    .children-setting-wrapper {
        .dashboard-family-tabs {
            .pgHeading {
                .title {
                    @media (max-width:1024px) {
                        @include page-title-ipad();
                    }
                }

                .routeBack-invest-abs {
                    @media (max-width:575px) {
                        top: 15px;
                    }
                }
            }
        }
    }

    .mobile-btn-266 {
        @media (max-width:575px) {
            font-size: var(--font-16) !important;
            padding: 10px 20px !important;
        }
    }

    .mobile-view-tabs-listing {
        li {
            @media (max-width:575px) {
                font-size: var(--font-13)
            }
        }

        @media (max-width:575px) {
            margin-top: 0;
        }
    }

    .routeBack-invest-abs {
        span.back {
            img {
                @media (max-width:575px) {
                    width: 19px !important;
                }
            }
        }
    }

    .routeBack-abs {
        img {
            @media (max-width:575px) {
                width: 19px !important;
            }
        }
    }

    .routeBack-flat {
        span {
            img {
                @media (max-width:575px) {
                    width: 19px;
                }
            }
        }
    }

    .page-parent-digital-wallet,
    .page-parent-digital-saving,
    .page-parent-digital-goals {
        .routeBack-abs {
            top:-25px;
            @media (max-width:575px) {
                top: 12px;
            }
        }

        .digital-wallet-wallet {
            .wallet-amount {
                .amount {
                    @media (max-width:1024px) {
                        font-size: var(--font-30);
                        line-height: 40px;
                    }
                }

                @media (max-width:575px) {
                    top: 35px;
                    padding-top: 20px;
                }
            }

            .transaction-heading {
                @media (max-width:575px) {
                    top: 141px;
                }
            }
        }

    }


    .recentTran-wrapper {
        .singlePendingTransaction {
            table {
                thead {
                    tr {
                        th {
                            @media (max-width:1024px) {
                                font-size: var(--font-14);
                            }
                        }
                    }
                }

                tbody {
                    td {
                        .avtar {
                            @media (max-width:1024px) {
                                width: 38px;
                                height: 38px;
                                min-width: 38px;
                                min-height: 38px;
                            }
                        }

                        h5 {
                            @media (max-width:1024px) {
                                font-size: var(--font-13);
                                line-height: 1.250rem;
                            }
                        }

                        @media (max-width:1024px) {
                            padding: 12px 0px;
                            font-size: var(--font-13);
                        }
                    }
                }
            }
        }
    }

    .wallet-page {
        .topLinks {
            button.btn-gradient {
                @media (max-width:575px) {
                    min-width: auto !important;
                    font-size: var(--font-13) !important;
                }
            }

            @media (max-width:575px) {
                top: 12px;
            }
        }

        .wallet-wrapper-new {
            .wallet-amount {
                .total-amount {
                    @media (max-width:1024px) {
                        font-size: var(--font-30);
                        line-height: 40px;
                    }
                }
            }

            .wallet-saving-stripe {
                a {
                    .wallet-saving-stripe-single {
                        .label {
                            .title {
                                @media (max-width:1024px) {
                                    font-size: var(--font-14);
                                }
                            }
                        }
                    }
                }
            }
        }

        .pendingTran-wrapper,
        .recentTran-wrapper {
            h2 {
                .no-pending-timeline {
                    @media (max-width:1024px) {
                        font-size: inherit;
                    }

                }

                @media (max-width:1024px) {
                    font-size: var(--font-16);
                }


            }
        }
    }

    // .page-parent-education-detail-contentId{
    .education-wrapper {
        position: relative;

        .educationBack {
            button {
                @media (max-width:575px) {
                    padding: 0 !important;
                    min-width: auto !important;
                }
            }

            @media (max-width:1024px) {
                top: -26px;
                margin-bottom: 0;
            }

            @media (max-width:575px) {
                top: 12px;
            }
        }

        .education-landing {

            @media (max-width:575px) {
                padding-top: 30px;
            }
        }

        .education-single {
            .edu-content {
                h4 {
                    @media (max-width:1024px) {
                        font-size: var(--font-13) !important;
                        line-height: 18px !important;
                    }

                }
            }

            .edu-thumbnail {
                @media (max-width:1024px) {
                    min-width: 75px;
                    min-height: 45px;
                }
            }

            @media (max-width:1024px) {
                margin-bottom: 1.250rem;
            }
        }

        .education-dtl-page {
            .mainHeading {
                @media (max-width:1024px) {
                    font-size: var(--font-18);
                }
            }

            embed {
                @media (max-width:1024px) {
                    width: 100%;
                }
            }

            .header-top {
                .page-title {
                    @media (max-width:575px) {
                        font-size: var(--font-16) !important;
                        line-height: 1rem;
                        padding-bottom: 10px;
                    }
                }

                .right-section {
                    .child-listing {
                        ul {
                            li {
                                @media (max-width:1024px) {
                                    width: 30px;
                                    height: 30px;
                                    min-width: 30px;
                                    min-height: 30px;
                                }
                            }
                        }
                    }
                }

                @media (max-width:575px) {
                    padding-top: 14px;
                }
            }
        }
    }

    // }

    .editProfileWrapper {
        .editUserAvatar {
            .userIconDisplayBox {
                img {
                    @media (max-width:1024px) {
                        bottom: -3px;
                        right: 5px;
                        width: 19px;
                    }
                }

                @media (max-width:1024px) {
                    width: 80px;
                    height: 80px;
                }
            }

            @media (max-width:1024px) {
                margin-top: 50px;
            }
        }

        .mobile-edit-btn{
            @media (max-width:575px) {
                top: 9px;
            }
        }

        .routeBack-abs {
            @media (max-width:575px) {
                top: 14px;
            }
        }

        .bottom-links {
            h3 {
                @media (max-width:1024px) {
                    font-size: var(--font-13);
                    padding: 0.625rem 0px;
                }
            }
        }

        .copyright-text {
            @media (max-width:1024px) {
                font-size: var(--font-13);
            }
        }
    }

    .commonModal {
        p {
            @media (max-width:1024px) {
                font-size: var(--font-13);
                line-height: 20px;
            }
        }
    }

    .modal-heading {
        @media (max-width:1024px) {
            font-size: var(--font-18);
            line-height: 1.250rem;
            margin-bottom: 22px;
        }

    }

    .successMarkBox {

        .checkcover {

            @media (max-width:1024px) {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                @media (max-width:1024px) {
                    width: 46px;
                }
            }
        }

        @media (max-width:1024px) {
            width: 122px;
            height: 122px;
            padding: 11px;
            border-radius: 61px;
        }
    }

    .successText {
        h3 {
            @media (max-width:1024px) {
                font-size: var(--font-16);
                line-height: 1.250rem;
            }

        }

        p {
            @media (max-width:1024px) {
                font-size: var(--font-13);
                line-height: 1.250rem;
            }

        }
    }

    .bank-add-success-modal {
        .banks-list {
            .bank-detail {
                .bank-name {
                    @media (max-width:1024px) {
                        font-size: var(--font-14);
                    }

                }

                .verified {
                    @media (max-width:1024px) {
                        font-size: var(--font-12);
                    }

                }
            }
        }
    }

    .investment-wrapper {
        .investment-welcome {
            h1.welcome-heading {
                @media (max-width:1024px) {
                    font-size: var(--font-15);
                    line-height: 16px;
                }

                img {
                    @media (max-width:1024px) {
                        top: 0px
                    }

                }
            }

            .welcome-content {
                h3 {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                        line-height: 20px;
                    }
                }

                p {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                        line-height: 20px;
                    }
                }
            }

            .special-pointes {
                p {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                    }
                }
            }

            .content-para {
                @media (max-width:1024px) {
                    font-size: var(--font-13);
                }
            }

            .custom-checkbox {
                label {
                    @media (max-width:1024px) {
                        font-size: var(--font-13);
                        line-height: 20px;
                    }
                }
            }
        }

        .investment-onboarding-header {
            .pg__tagLine {
                @media (max-width:1024px) {
                    font-size: var(--font-13);
                    margin-bottom: 0;
                }
            }

            .pg__title {
                @media (max-width:1024px) {
                    font-size: var(--font-16);
                }
            }

            .pg-desc {
                @media (max-width:1024px) {
                    font-size: var(--font-13);
                    line-height: 20px;
                }
            }
        }
    }

    .page-child-education {
        .education-wrapper {
            .top-buttons-group {

                @media (max-width:575px) {
                    top: 15px;
                }
            }
        }
    }

    .page-child-education-detail-contentId {
        .education-dtl-page {
            .header-top {
                @media (max-width:575px) {
                    padding-top: 16px;
                }

            }
        }
    }

    .page-child-digital-wallet,
    .page-child-digital-saving,
    .page-child-digital-goals {
        .top-transfer-button {
            @media (max-width:575px) {
                top: 10px;
                font-size: var(--font-13);
            }

        }

        .routeBack-abs {
            @media (max-width:575px) {
                top: 12px;
            }

        }

        .digital-wallet-wallet {
            .wallet-amount {
                @media (max-width:575px) {
                    top: 40px;
                }
            }
        }

        .transaction-heading {
            @media (max-width:575px) {
                top: 142px;
            }

        }
    }

    .page-child-chores {
        .chores-goal {
            .top-buttons-group {
                @media (max-width:575px) {
                    padding-top: 16px;
                }
            }

            .goals-tab-wrapper {
                .goal-filter-bar {
                    .mobile-add-goal-btn {
                        @media (max-width:575px) {
                            min-width: auto;
                        }

                    }

                    @media (max-width:575px) {
                        top: 95px;
                        padding-top: 5px;
                    }
                }

                .goals-progress-single {
                    .goal-progress-bar {
                        h5 {
                            span {
                                @media (max-width:575px) {
                                    font-size: var(--font-13);
                                }
                            }
                        }

                        .progress-value {
                            @media (max-width:575px) {
                                font-size: var(--font-13);
                            }
                        }
                    }
                }
            }
        }
    }

    .child-dashboard {
        .child-dashboard-breadcrumb {
            @media (max-width:575px) {
                padding: 16px 15px 20px;
            }
        }
    }

    .page-child-shopping {
        .child-shop-wrapper {
            .top-buttons-group {
                @media (max-width:575px) {
                    padding: 16px 15px 0px;
                }
            }
        }
    }

    .investment-page-title {
        span.watchlist-icon {
            @media (max-width:575px) {
                top: 13px;
            }
        }

        @media (max-width:1024px) {
            @include page-title-ipad();
        }
    }

    .investment-dashboard {
        .routeBack {
            img {
                @media (max-width:575px) {
                    width: 19px;
                }
            }

            @media (max-width:575px) {
                top: 9px;
            }
        }
    }

    .investment-portfolio-detail-wrapper {
        .routeBack-invest-abs {
            @media (max-width:575px) {
                top: 14px;
            }
        }

        .investment-detail-stock-amount {
            @media (max-width:575px) {
                margin-top: 15px;
            }
        }
    }



}


//TENANT SPECIFIC CSS
#tenant-college {
    .signupLanding {
        .signupLandingWrapper {
            &.login {
                .signupLandingtile {

                    .im-child,
                    .im-adult {
                        align-items: flex-end;

                        img{
                            max-height: 171px;
                        }
                    }
                }
            }

            &.registration {
                .signupLandingtile {

                    .create-account,
                    .accept-invite {
                        align-items: flex-end;
                    }
                }
            }
        }
    }
}

#tenant-q2{
    .dashboard-wrapper{
        .dashboard-right-article{
            &.no-sidebar{
                .loader-div{
                    height: 650px;
                }
            }
            &.page-parent-changePassword, &.page-parent-timeline {
                .dashboard-heading{
                    padding-top: 0;
                }
            }
            .dashboard-heading{
                padding-top: 0;
            }
            .page-parent-education{
                .dashboard-heading{
                    .heading-right-icon-list{
                        top: 7px;
                    }
                }
            }

            .scheduleTransfer, .receiptsWrapper, .statementWrapper {
                .routeBack-flat{
                    top:-57px;
                }
            }

            .tenant-menubar{
                ul{
                    li{
                        &.active{
                            a{
                                font-weight: 600;
                                &::after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .child-shop-wrapper, .shop-wrapper{
        .tiles-cards-group{
            .dashboard-children-card{
                .singleRetailer{
                    .tile-background{
                        @media (max-width:1440px) {
                            height: 110px;
                          }
                
                          @media (max-width:1200px) {
                            height: 113px;
                          }
                
                          @media (max-width:1024px) {
                            height: 110px;
                          }
                
                          @media (max-width:820px) {
                            height: 106px;
                          }
                
                          @media (max-width:575px) {
                            height: 95px;
                          }
                
                          @media (max-width:480px) {
                            height: 115px;
                          }
                
                          @media (max-width:425px) {
                            height: 103px;
                            background-position: center;
                          }
                
                          @media (max-width:416px) {
                            height: 101px;
                          }
                
                          @media (max-width:412px) {
                            height: 100px;
                          }
                    }
                }
            }
        } 
        
        
    }

    .trusted-contact-wrapper{
        .routeBack-abs{
            top: -58px;
        }
    }


    .no-data-overlay-education,
      .no-data-overlay-wallet,
      .no-data-overlay-savings,
      .no-data-overlay-goals,
      .no-data-overlay-schTransfer,
      .no-data-overlay-receipts,
      .no-data-overlay-statements,
      .no-data-overlay-investment-timeline,
      .no-data-overlay-timeline,
      .no-data-overlay-dashboard-addBank,
      .no-data-overlay-dashboard-chores-empty,
      .no-data-overlay-dashboard-chores-noUser,
      .no-data-overlay-dashboard-chores,
      .no-data-overlay-dashboard,
      .no-data-overlay-shopping,
      .no-data-overlay-retailer-search,
      .no-data-overlay-charity-search,
      .no-data-overlay-shopping-child,
      .no-data-overlay-shopping-child-search,
      .no-data-overlay-shopping-charities,
      .no-data-overlay-family-children,
      .no-data-overlay-family-children-adult,
      .no-data-overlay-family-physicalcard,
      .no-data-overlay-childInvest-timeline,
      .no-data-overlay-childInvest-reports,
      .no-data-overlay-childInvest-watchlist,
      .no-data-found-peers-tab,
      .no-data-overlay-choresGoals,
      .no-data-overlay-education-child {
        width: 100%;
        max-width: 100%;
        left: 0;
        right: 0;
        transform: none;
        height:300px;
      }

      .custom-control-input:checked~.custom-control-label::before {
        background-color: var(--primaryColor) !important;
        border-color: var(--primaryColor) !important;
      }
}

#tenant-banno{
    padding-top: 30px;
    background-color: #fff !important;
    .add-chore-wrapper1{
        .title, .description, .choreSuggestions, .rewards, .child-list, .time, .schedule{
            width:100%;
        }
    }   
}