@mixin tabs-font{
    font-size: var(--font-13);
    line-height: 1.125rem;
}

@mixin tabs-font-ipad{
    font-size: var(--font-15);
    line-height: var(--font-19);
    padding:0px 0px 6px 0px;
}

@mixin tabs-font-mobile{
    font-size: var(--font-13);
    line-height: 1.125rem;
}

@mixin page-title{
    font-size: var(--font-15);
}

@mixin page-title-ipad{
    font-size: var(--font-15) !important;
    line-height: 1rem;
    padding-bottom: 10px;
}

@mixin page-title-mobile{
    font-size: var(--font-15);
}