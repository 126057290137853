$primary-color: #025159;
$secondary-color: #212529;
$grey-color: #ededed;
$child-theme : #F2921D;
$theme-green : #025159;
$child-investment-bg : #f2e6da;
$parent-investment-bg : #daeae9;
$parent-investment-bg1 : #b6d7d5;
$label-highlight : #243537;
$grey-4 : #7c8687;
$black-1 : #424242;

.investment-page-title {
  font-weight: 700;
  font-size: var(--font-20);
  line-height: 21px;
  color: var(--primaryColor);
  margin-bottom: 31px;
  position: relative;
  text-align: center;

  .goBack {
    position: absolute;
    left: 0;
    cursor: pointer;
    font-size: var(--font-18);
    line-height: 22px;
    font-weight: 500;
    color: #243537;

    img {
      margin-top: -3px;
    }
  }

  span.watchlist-icon {
    position: absolute;
    right: 0;
    top: -3px;
    cursor: pointer;

    @media (max-width:575px) {
      top: 17px;
      right: 15px;
      display: flex;
      align-items: center;
    }
  }

  .routeBack-invest-abs {
    @media (max-width:575px) {
      top: 10px;
      left: 15px;
    }
  }

  @media (max-width:1024px) {
    margin-bottom: 36px;
  }

  @media (max-width:575px) {
    font-size: var(--font-18) !important;
    font-weight: 700 !important;
    padding-top: 14px;
    text-align: center;
    position: sticky;
    top: 0;
    padding-bottom: 25px;
    background: transparent;
    z-index: 9;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0 !important;
  }
}

.investment-wrapper {

  //STEP DESIGN FOR ONBOARDING
  .signUpStepForm-wrapper {
    width: 722px;
    max-width: 722px;
    margin: 51px auto;
    padding-bottom: 51px;

    .stepNumbers-list {
      margin: 0px auto 36px auto;
      position: relative;
      align-items: center;

      img {
        margin-bottom: 14px;
      }


      .progressWrapper {
        position: relative;
        width: 671px;
        margin: auto;

        .stepProgressBar {
          position: absolute;
          background: var(--primaryColor);
          height: 9px;
          min-width: 5%;
          border-radius: 7px;
        }

        .stepBackground {
          background-color: #f4f5f5;
          border-radius: 5px;
          width: 100%;
          height: 9px;
        }
      }
    }
  }

  //ONBOARDING HEADER TITLE AND SUB TITLE
  .investment-onboarding-header {
    .pg__tagLine {
      color: var(--tertiaryColor);
      font-size: var(--font-16);
      line-height: 18px;
      margin-bottom: 7px;
      font-weight: 700;
    }

    .pg__title {
      color: var(--primaryColor);
      font-weight: 700;
      font-size: var(--font-24);
      line-height: 27px;
      margin-bottom: 16px;

      @media (max-width: 480px) {
        font-size: var(--font-21);
        line-height: 30px;
      }
    }

    .pg-desc {
      color: $grey-4;
      font-weight: 400;
      font-size: var(--font-14);
      line-height: 20px;
      letter-spacing: 0.01em;
      margin-bottom: 24px;
      max-width: 500px;
    }
  }

  .registration-progress-bar {
    width: 500px;
    margin: 0px auto 25px;
    display: flex;
    align-items: center;
    column-gap: 20px;

    .back-arrow {}

    .progressWrapper {
      position: relative;
      width: 100%;

      .stepProgressBar {
        position: absolute;
        background: var(--primaryColor);
        height: 9px;
        min-width: 5%;
        border-radius: 7px;

        @media (max-width:575px) {
          height: 4px;
        }
      }

      .stepBackground {
        background-color: #f4f5f5;
        border-radius: 5px;
        width: 100%;
        height: 9px;

        @media (max-width:575px) {
          height: 4px;
        }
      }
    }

    @media (max-width:575px) {
      width: 100%;
      padding-top: 10px;
      position: sticky;
      top: 0;
      background: #fff;
      padding-bottom: 10px;
    }
  }

  //INVESTMENT WELCOME SCREEN
  .investment-welcome {
    width: 500px;
    max-width: 500px;
    margin: auto;

    h1.welcome-heading,
    .h1.welcome-heading {
      font-weight: 700;
      font-size: var(--font-30);
      line-height: 30px;
      color: var(--primaryColor);
      margin-bottom: 30px;
      letter-spacing: -0.02em;
      position: relative;
      text-align: center;

      img {
        position: absolute;
        left: 0;
        top: 5px;
      }

      @media (max-width:575px) {
        text-align: center;
        font-size: var(--font-18);
        position: sticky;
        top: 0;
        padding: 10px 0px;
        background: #fff;

        img {
          top: 11px !important
        }
      }
    }

    .welcome-content {

      h3,
      .h3 {
        font-weight: 700;
        font-size: var(--font-20);
        line-height: 24px;
        margin-bottom: 8px;
        color: var(--primaryColor);
      }

      p {
        font-weight: 400;
        font-size: var(--font-16);
        line-height: 22px;
        color: $grey-4;
        letter-spacing: 0.01em;
      }
    }

    .special-pointes {
      padding: 16px;
      background: #FFFFFF;
      border-radius: 16px;
      margin-bottom: 16px;

      p {
        font-weight: 700;
        font-size: var(--font-14);
        line-height: 18px;
        color: var(--primaryColor);
        letter-spacing: 0.01em;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .content-para {
      color: $grey-4;
      font-weight: 400;
      font-size: var(--font-16);
      line-height: 22px;
      letter-spacing: 0.01em;
      margin-bottom: 16px;
    }

    .custom-checkbox {
      margin-top: 32px;
      margin-bottom: 32px;

      label {
        color: $grey-4;
        font-weight: 400;
        font-size: var(--font-13);
        line-height: 18px;
        cursor: pointer;
        padding-left: 20px;
        position: relative;

        &::before {
          width: 20px;
          height: 20px;
          top: calc(50% - 10px);
          left: -1.5rem;
          cursor: pointer;
        }

        &::after {
          width: 20px;
          height: 20px;
          background-size: 12px;
          top: calc(50% - 10px);
          left: -1.5rem !important;
          cursor: pointer;
        }
      }
    }

    @media (max-width:575px) {
      width: 100%;
    }
  }

  //INVESTMENT IDENTITY SCREEN
  .invest-identity-info {
    .form-box {
      width: 500px;

      .form-group {
        margin-bottom: 32px;

        input[disabled] {
          background-color: transparent;
          color: $grey-4;
        }

        select.custom-select {
          border: none;
          border-bottom: 1px solid #243537;
          color: #243537;
          border-radius: 0px;
          font-weight: 500;
          font-size: var(--font-16);
          padding: 6px 3px;
          height: 40px;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .states-list {
        select {
          border-radius: 0;
          font-size: var(--font-16);
          line-height: 24px;
          background-color: transparent;
          padding: 6px 0px;
          height: 40px;
          border: none;
          border-bottom: 1px solid #243537;
          color: #243537;
          border-radius: 0px;

          &:disabled {
            opacity: 0.7;
          }
        }
      }

      .half-box {
        display: flex;
        column-gap: 20px;

        .form-group {
          width: 50%;
        }
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }

  //INVESTMENT FINANCIAL PROFILE
  .invest-financial-profile {
    .form-box {
      width: 500px;

      .form-group {
        margin-bottom: 32px;

        select {
          font-size: var(--font-16);
          line-height: var(--font-24);
          color: #243537;
          border: none;
          border-bottom: 1px solid $grey-4;
          border-radius: 0px;
          padding: 10px 10px 10px 3px;
          height: auto;
          font-weight: 500;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }

  //INVESTMENT AFFILIATION
  .invest-affiliation {
    .form-box {
      width: 500px;

      .form-group {
        margin-bottom: 32px;
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }

  //INVESTMENT AFFILIATION
  .invest-affiliationQA {
    .form-box {
      width: 500px;

      .form-group {
        margin-bottom: 32px;

        label.qa {
          color: var(--primaryColor);
          font-size: var(--font-16);
          line-height: 24px;
          margin-bottom: 8px;
        }

        p.pg-desc {
          color: $grey-4;
          font-size: var(--font-14);
          letter-spacing: 0.01em;
          line-height: 20px;
        }

        select {
          font-size: var(--font-16);
          line-height: var(--font-24);
          color: #243537;
          border: none;
          border-bottom: 1px solid $grey-4;
          border-radius: 0px;
          padding: 10px 10px 10px 3px;
          height: auto;
          font-weight: 500;
          margin-bottom: 15px;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }

  //INVESTMENT SETUP SUCCESS
  .investment-setup-success {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          background: #1aa596;
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    p.setupAc {
      font-size: var(--font-16);
      font-weight: 700;
      color: var(--primaryColor);
      line-height: 24px;
      text-align: center;
      margin-top: 16px;
    }

    p.doLater {
      color: $grey-4;
      font-size: var(--font-16);
      line-height: 24px;
      margin-top: 16px;
      text-align: center;
      cursor: pointer;
    }
  }

  //INVESTMENT SETTINGS
  .setting-investment {
    position: relative;

    .header-row {
      padding-top: 0px;
      position: sticky;
      top: 0;
      z-index: 9;
      background: #fff;
      padding-bottom: 10px;
      margin-bottom: 10px;
      display: none;

      @media (max-width:575px) {
        padding: 0;
        display: block;
        position: fixed;
        top: 14px;
        right: 0;
      }
    }

    .pgHeading {
      font-size: var(--font-25);
      margin-bottom: 50px;
      color: var(--primaryColor);
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 21px;

      @media (max-width:575px) {
        font-size: var(--font-18) !important;
        margin-bottom: 0 !important;
      }
    }

    .routeBack-abs {
      top: -1px;

      @media (max-width:575px) {
        left: 15px;
        top: 10px;
        z-index: 9;
      }
    }

    .btn-edit {
      position: absolute;
      top: 0px;
      right: 0;
      font-size: var(--font-14);
      font-weight: normal;
      color: var(--primaryColor);
      cursor: pointer;

      @media (max-width:575px) {
        position: initial;
      }
    }

    .goBack {
      font-size: var(--font-18);
      font-weight: 500;
      line-height: 24px;
      color: #243537;
      position: absolute;
      top: 7px;
      z-index: 9;

      img {
        margin-top: -3px
      }

      @media (max-width:480px) {
        font-size: var(--font-14);
        top: 0;
      }
    }

    .selectInvestment {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      h3,
      .h3 {
        font-size: var(--font-16);
        line-height: 24px;
        font-weight: 400;
        color: var(--primaryColor);
        margin-bottom: 0px;
      }

      @media (max-width:575px) {
        padding: 0px 15px;
      }
    }

    .parent-wrapper,
    .children-wrapper {
      align-items: center;

      h2.blockHeading,
      .h2.blockHeading {
        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 12px;
        margin-bottom: 0px;
        margin-top: 0px;
        line-height: 1.2;

        @media (max-width:575px) {
          border-radius: 0;
          padding: 9px 15px;
        }
      }

      .childLimitSingleBox {
        padding: 15px 12px;
        border-bottom: 1px solid #F4F5F5;

        .title-row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .avtar {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            background: #f4f5f5;
            border-radius: 24px;
            text-align: center;
            margin-right: 8px;
            overflow: hidden;
          }

          h3,
          .h3 {
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--primaryColor);
            opacity: 0.7;
            margin: 0px;
            font-weight: 500;
            width: 100%;
          }
        }

        .enableDisable {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;

          h3,
          .h3 {
            font-size: var(--font-16);
            line-height: 24px;
            font-weight: 400;
            color: var(--primaryColor);
            margin-bottom: 0px;
          }

          .custom-control-input {
            &:disabled~.custom-control-label {
              &::before {
                opacity: 0.8;
              }
            }
          }

          // .custom-control-input:disabled~.custom-control-label::before

          .inputBox {
            position: relative;

            input {
              font-size: var(--font-16);
              color: #243537;
              border: none;
              line-height: 24px;
              width: 100%;
              letter-spacing: -0.03em;
              padding-top: 0px;
              padding-bottom: 0px;
              height: 24px;
              text-align: right;

              &:focus {
                box-shadow: none;
                outline: none;
              }
            }

            input::placeholder {
              color: var(--tertiaryGrayColor);
              font-size: var(--font-16);
              line-height: 24px;
              font-weight: 100;
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }

          @media (max-width:480px) {
            margin-bottom: 10px;
          }
        }

        &:first-child {
          padding-top: 0px;
        }

        &:last-child {
          border-bottom: 0px;
        }

        @media (max-width:575px) {
          padding: 17px 15px !important;
        }
      }
    }

    .terminate-investment {
      font-weight: 400;
      font-size: var(--font-16);
      line-height: 24px;
      text-align: center;
      color: #F20505;
      margin-top: 32px;
      margin-bottom: 20px;

      span {
        cursor: pointer;
      }
    }

    @media (max-width:1024px) {
      .pgHeading {
        font-size: var(--font-25);
        margin-bottom: 15px;
      }

      .selectInvestment {
        margin-bottom: 12px;

        h3,
        .h3 {
          font-size: var(--font-14);
          line-height: 20px;
        }
      }

      .parent-wrapper,
      .children-wrapper {
        .childLimitSingleBox {
          padding: 10px 0px;

          .title-row {

            h3,
            .h3 {
              font-size: var(--font-14);
              line-height: 20px;
            }

            .avtar {
              width: 40px;
              height: 40px;
              min-width: 40px;
              min-height: 40px;
              line-height: 38px;
            }
          }

          .enableDisable {

            h3,
            .h3 {
              font-size: var(--font-14);
              line-height: 20px;
            }
          }
        }
      }
    }

    @media (max-width:575px) {
      margin-top:0;
      width: calc(100% + 30px);
      max-width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  //INVESTMENT SETTINGS
  .setting-investmentOnly {
    margin: 50px auto 0;
    position: relative;

    .header-row {
      padding-top: 0px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      position: relative;

      @media (max-width:575px) {
        padding-top: 14px;
      }
    }

    .pgHeading {
      font-size: var(--font-25);
      margin-bottom: 50px;
      color: var(--primaryColor);
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 21px;

      @media (max-width:575px) {
        font-size: var(--font-18) !important;
        margin-bottom: 0 !important;
      }
    }

    .btn-edit {
      position: absolute;
      top: 0px;
      right: 0;
      font-size: var(--font-14);
      font-weight: normal;
      color: var(--primaryColor);
      cursor: pointer;

      @media (max-width:575px) {
        right:15px;   
        top:15px;   
      }
    }

    .goBack {
      font-size: var(--font-18);
      font-weight: 500;
      line-height: 24px;
      color: #243537;
      position: absolute;
      top: 7px;
      z-index: 9;

      img {
        margin-top: -3px
      }

      @media (max-width:480px) {
        font-size: var(--font-14);
        top: 0;
      }
    }

    .selectInvestment {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      h3,
      .h3 {
        font-size: var(--font-16);
        line-height: 24px;
        font-weight: 400;
        color: var(--primaryColor);
        margin-bottom: 0px;
      }

      @media (max-width:575px) {
        padding: 0px 15px;
      }
    }

    .parent-wrapper{
      align-items: center;

      h2.blockHeading,
      .h2.blockHeading {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 7px 7px;
        margin-bottom: 0px;
        margin-top: 0px;
        line-height: 1.2;

        @media (max-width:575px) {
          border-radius: 0;
          padding: 9px 15px;
        }
      }

      .childLimitSingleBox {
        padding: 32px 0px;
        border-bottom: 1px solid #F4F5F5;

        .title-row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .avtar {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            background: #f4f5f5;
            border-radius: 24px;
            text-align: center;
            margin-right: 8px;
            overflow: hidden;
          }

          h3,
          .h3 {
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--primaryColor);
            opacity: 0.7;
            margin: 0px;
            font-weight: 500;
            width: 100%;
          }

          .custom-control-input {
            &:disabled~.custom-control-label {
              &::before {
                opacity: 0.8;
              }
            }
          }
        }

        .enableDisable {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;

          h3,
          .h3 {
            font-size: var(--font-16);
            line-height: 24px;
            font-weight: 400;
            color: var(--primaryColor);
            margin-bottom: 0px;
          }

          .custom-control-input {
            &:disabled~.custom-control-label {
              &::before {
                opacity: 0.8;
              }
            }
          }

          // .custom-control-input:disabled~.custom-control-label::before

          .inputBox {
            position: relative;

            input {
              font-size: var(--font-16);
              color: #243537;
              border: none;
              line-height: 24px;
              width: 100%;
              letter-spacing: -0.03em;
              padding-top: 0px;
              padding-bottom: 0px;
              height: 24px;
              text-align: right;

              &:focus {
                box-shadow: none;
                outline: none;
              }
            }

            input::placeholder {
              color: var(--tertiaryGrayColor);
              font-size: var(--font-16);
              line-height: 24px;
              font-weight: 100;
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }

          @media (max-width:480px) {
            margin-bottom: 10px;
          }
        }

        &:first-child {
          padding-top: 0px;
        }

        &:last-child {
          border-bottom: 0px;
        }

        @media (max-width:575px) {
          padding: 17px 15px !important;
        }
      }
    }

    .terminate-investment {
      font-weight: 400;
      font-size: var(--font-16);
      line-height: 24px;
      text-align: center;
      color: #F20505;
      margin-top: 100px;
      margin-bottom: 20px;

      span {
        cursor: pointer;
      }

      @media (max-width:575px){
        position: fixed;
        width: 100%;
        bottom: 70px;
      }
    }

    @media (max-width:1024px) {
      .pgHeading {
        font-size: var(--font-25);
        margin-bottom: 15px;
      }

      .selectInvestment {
        margin-bottom: 12px;

        h3,
        .h3 {
          font-size: var(--font-14);
          line-height: 20px;
        }
      }

      .parent-wrapper {
        .childLimitSingleBox {
          padding: 10px 0px;

          .title-row {

            h3,
            .h3 {
              font-size: var(--font-14);
              line-height: 20px;
            }

            .avtar {
              width: 40px;
              height: 40px;
              min-width: 40px;
              min-height: 40px;
              line-height: 38px;
            }
          }

          .enableDisable {

            h3,
            .h3 {
              font-size: var(--font-14);
              line-height: 20px;
            }
          }
        }
      }
    }

    @media (max-width:575px) {
      margin-top:0;
      width: calc(100% + 30px);
      max-width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

//INVESTMENT TERMINATE/SUSPAND MODAL
.investment-terminate-modal {

  h1.modal-heading,
  .h1.modal-heading {
    margin-bottom: 30px;
  }
}

//INVESTMENT DASHBOARD
.investment-dashboard {
  width: 100%;

  .toggleInvestOnlyIcon{
    position: absolute;
    right:0;
    cursor: pointer;
    z-index: 1;
    svg{
      width:21px;
      fill: var(--tertiaryGrayColor);
    }

    @media (max-width:575px){
      z-index: 10;
      top: 11px;
    }
  }

  .investment-page-title {
    display: none;

    @media (max-width:575px) {
      display: block;
    }
  }

  .investTenant-settings-icon{
    display: none;

    @media (max-width:575px){
      display: initial;
      position: absolute;
    left: 15px;
    }
  }

  // .route-back {
  //   display: none;
  //   position: sticky;
  //   top: 0px;
  //   padding-top: 10px;
  //   padding-bottom: 31px;

  //   @media (max-width:575px) {
  //     display: block;
  //   }
  // }

  .routeBack {
    display: none;

    @media (max-width:575px) {
      display: block;
      position: fixed;
      top: 12px;
      z-index: 9;
      left: 15px;
    }
  }

  .investment-amount-display {
    .total-amount {
      position: relative;
      // display: flex;
      // justify-content: center;
      // column-gap: 60px;

      .practice-mode,
      .available-practice {
        text-align: center;

        h3,
        .h3 {
          color: var(--primaryColor);
          font-size: var(--font-20);
          margin-bottom: 8px;
          font-weight: 600;

          @media (max-width:575px) {
            font-size: var(--font-18);
            line-height: 30px;
          }
        }

        @media (max-width:575px) {
          width: 100%;
        }
      }

      .limits-row {
        display: flex;
        align-items: center;
        column-gap: 30px;
        justify-content: center;
        margin-top: 15px;

        .buying-limit {
          text-align: center;

          h3,
          .h3 {
            font-size: var(--font-14);
            font-weight: 700;
            color: var(--primaryColor);

            span.infoIcn {
              margin-left: 7px;
            }
          }

          p {
            color: $theme-green;
            font-size: var(--font-14);
            font-weight: 700;
          }
        }
      }

      .available-practice {
        @media (max-width:575px) {
          margin-top: 20px;
        }
      }

      .amountVal {
        font-size: var(--font-36);
        line-height: 40px;
        color: #0A0A0A;
        display: flex;
        font-weight: 100;
        align-items: center;
        justify-content: center;
        margin-top: 27px;

        img {
          margin-left: 10px;
          margin-top: 3px;

          &.rotate180 {
            transform: rotate(180deg);
          }
        }
      }

      .settingBtn {
        position: absolute;
        right: 0;
        top: 0px;

        button {
          display: none;
        }

        img {
          cursor: pointer;
        }
      }

      @media (max-width:575px) {
        flex-wrap: wrap;
      }
    }

    .balance-label {
      color: var(--tertiaryGrayColor);
      font-size: var(--font-14);
      font-weight: 400;
      line-height: var(--font-21);
      margin-bottom: 0;
      text-align: center;
    }

    @media (max-width:991px) {
      .total-amount {
        .settingBtn {
          top: 5px;

          button {
            display: none;
          }

          img {
            margin-top: 5px;
            display: initial;
          }
        }
      }
    }

    @media (max-width:480px) {
      .total-amount {
        .amountVal {
          font-size: var(--font-35);
        }
      }
    }

    @media (max-width:375px) {
      .total-amount {
        .amountVal {
          font-size: var(--font-30);
          line-height: 40px;
        }
      }
    }
  }

  .investment-graph {
    margin-bottom: 32px;
    margin-top: 32px;
    text-align: center;

    img {
      height: 240px;
    }
  }

  .no-stock-available {
    padding: 10px;
    border-radius: 12px;
    border: 1px solid var(--primaryColor);
    background: #fff;
    margin-top: 20px;

    p {
      margin-bottom: 0px;
      color: var(--primaryColor);
      line-height: 26px;
      font-size: var(--font-16);
    }
  }

  .investment-tabs {
    .pending-history {
      margin-bottom: 40px;

      h4,
      .h4 {
        display: flex;
        font-size: var(--font-16);
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 30px;
        color: var(--primaryColor);
        position: relative;

        span {
          margin-left: auto;
          cursor: pointer;
          font-size: var(--font-14);
          font-weight: 600;
        }

        &.parent-theme {
          &::after {
            content: '';
            width: 70px;
            height: 2px;
            background: var(--primaryColor);
            left: 0;
            position: absolute;
            bottom: -1px;
          }
        }

        &.child-theme {
          &::after {
            content: '';
            width: 70px;
            height: 2px;
            background: var(--primaryColor);
            left: 0;
            position: absolute;
            bottom: -1px;
          }
        }



        &.no-border {
          &::after {
            display: none;
          }
        }
      }
    }

    .no-stock {
      text-align: center;

      .no-data-title {
        text-align: center;
        font-size: var(--font-14);
        line-height: 22px;
        margin-bottom: 5px;
        color: $grey-4;
        font-weight: 600;
      }
    }

    .investment-portfolios {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;

      .investment-portfolio-card {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
        border-radius: var(--borderRadiusL);
        display: flex;
        align-items: center;
        padding: 14px;
        background-color: #fff;
        margin-bottom: 20px;
        cursor: pointer;
        width: calc(50% - 15px);

        @media (max-width:820px) {
          width: 100%;
        }

        .portfolio-logo {
          width: 40px;
          height: 40px;
          min-height: 37px;
          min-width: 37px;
          // background: #F4F5F5;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 11px;

          img {
            // width: 15px;
            // filter: grayscale(1);
          }
        }

        .portfolio-details {
          width: 100%;

          .first-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2px;

            .company {
              font-size: var(--font-14);
              line-height: 21px;
              letter-spacing: -0.02em;
              color: #243537;
              overflow: hidden;
              font-weight: 700;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;

              @media (max-width:480px) {
                font-size: var(--font-14);
              }
            }

            .amount {
              font-weight: 600;
              font-size: var(--font-14);
              line-height: 21px;
              letter-spacing: -0.02em;
              color: $theme-green;
              padding-left: 15px;

              @media (max-width:480px) {
                font-size: var(--font-14);
              }
            }
          }

          .bottom-row {
            display: flex;
            align-items: center;

            .symbol {
              font-weight: 500;
              font-size: var(--font-13);
              line-height: 24px;
              letter-spacing: -0.02em;
              color: $black-1;
              margin-bottom: 0px;
              white-space: nowrap;
              text-transform: uppercase;

              span.watchlist-indicator {
                text-transform: initial;
              }

              @media (max-width:480px) {
                line-height: 21px;
              }
            }

            .graph-box {
              position: relative;
              margin-left: auto;
              margin-right: 80px;

              canvas {
                width: 90px !important;
                height: 24px !important;

                @media (max-width:480px) {
                  height: 21px !important;
                }
              }

              .line-stroke {
                width: 100%;
                position: absolute;
                top: calc(50% - 1px);
              }

              @media (max-width:480px) {
                margin-right: 30px;
              }
            }

            .profitLossPercent {
              font-weight: 500;
              font-size: var(--font-13);
              line-height: 24px;
              letter-spacing: -0.02em;
              margin-bottom: 0;
              text-align: right;

              &.positive {
                color: var(--tertiaryColor);
              }

              &.negative {
                color: var(--errorColor);
              }

              @media (max-width:480px) {
                line-height: 21px;
              }
            }

            .cancel-order {
              font-weight: 500;
              font-size: var(--font-13);
              line-height: 24px;
              letter-spacing: -0.02em;
              margin-bottom: 0;
              text-align: right;
              margin-left: auto;

              &.positive {
                color: var(--primaryColor);
              }

              &.negative {
                color: var(--errorColor);
              }
            }
          }
        }

        .portfolio-name {
          margin-right: 10px;
          width: calc(100% - 257px);

          h3,
          .h3 {
            font-size: var(--font-16);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #243537;
            overflow: hidden;
            margin-bottom: 2px;
            font-weight: 700;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            @media (max-width:320px) {
              max-width: 70px;
            }
          }

          p {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: $theme-green;
            margin-bottom: 0px;
            white-space: nowrap;
            text-transform: uppercase;
          }
        }

        .portfolio-graph {
          margin-left: auto;
          margin-right: auto;
          width: 120px;

          .d1 {
            canvas {
              width: 120px !important;
              height: 44px !important;
            }
          }
        }

        .portfolio-amount {
          margin-left: auto;
          margin-right: 10px;
          width: auto;
          text-align: right;
          max-width: 150px;
          min-width: 120px;

          h3,
          .h3 {
            font-weight: 600;
            font-size: var(--font-16);
            line-height: 24px;
            text-align: right;
            letter-spacing: -0.02em;
            color: $theme-green;
            margin-bottom: 2px;
          }

          p {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-bottom: 0;
            text-align: right;

            &.positive {
              color: var(--primaryColor);
            }

            &.negative {
              color: var(--errorColor);
            }
          }

          @media (max-width:480px) {
            margin: 0px 10px;
            margin-left: auto;
          }
        }

        .portfolio-buy {
          @media (max-width:480px) {
            margin-left: 0px;
          }
        }
      }

      &.reseach-landing-cards {
        .investment-portfolio-card {
          .portfolio-amount {
            width: 100px;
          }

          .portfolio-graph {
            width: 120px;
            min-width: 120px;
          }

          .portfolio-name {
            width: calc(100% - 257px);
          }
        }
      }
    }
  }
}

//INVESTMENT SETTINGS MENU LIST
.investment-settings-menu-list{ 

  h3{
    font-size: var(--font-18);
    padding: 10px 15px 20px !important;
    position: sticky;
    top: 0px;
    text-align: center;
    line-height: 30px;
    color: var(--primaryColor);
    font-weight: 700;

    .routeBack{
      left: 0;
      position: absolute;
      top: 9px;

      @media (max-width:575px){
        top:13px;
      }
    }

    @media (max-width:575px){
      padding: 14px 15px 20px !important;
    }
  }

  .list-wrapper{
    li{
      list-style: none;
      padding: 13px 0px;
      border-bottom: 1px solid #f4f5f5;
      font-size: var(--font-14);
      color: var(--primaryColor);
      display: flex;
      align-items: center;
  
      a{
        display: flex;
        align-items: center;
        width:100%;
      }
  
      svg.menu-icon{
        width:22px;
        margin-right: 5px;
        display: flex;
        align-items: center;
      }

      .rightIcon{
        margin-left: auto;
      }
    }
  }
  
}

//INVESTMENT PORTFOLIO DETAIL
.investment-portfolio-detail-wrapper {
  .portfolio-dtl-heading {
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: calc(100% - 90px);
    overflow: hidden;
    margin: auto;
  }

  .routeBack-invest-abs {
    top: 0px;

    @media (max-width:575px) {
      top: 21px;
    }
  }

  .investment-detail-title {
    font-weight: 700;
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.back {
      position: absolute;
      left: 0;
      cursor: pointer;
    }

    span.watchlist-icon {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }

  .investment-detail-stock-amount {
    padding: 16px;
    box-shadow: 0px 2px 3px #d5d5d5;
    border-radius: 16px;
    background-color: #fff;

    .stock-amount-grp {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #F4F5F5;

      .stock-name {
        display: flex;
        align-items: center;
        width: 100%;

        .portfolio-logo {
          width: 40px;
          height: 40px;
          // background: #F4F5F5;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;

          img {
            width: 40px;
            // filter: grayscale(1);
          }
        }

        .portfolio-details {
          width: 100%;

          .first-row {
            margin-bottom: 2px;

            .company {
              font-size: var(--font-16);
              line-height: 21px;
              letter-spacing: -0.02em;
              color: #243537;
              overflow: hidden;
              font-weight: 700;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;

              @media (max-width:480px) {
                font-size: var(--font-14);
              }
            }
          }

          .bottom-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .symbol {
              font-weight: 500;
              font-size: var(--font-14);
              line-height: 24px;
              letter-spacing: -0.02em;
              color: $black-1;
              margin-bottom: 0px;
              white-space: nowrap;
              text-transform: uppercase;

              span.watchlist-indicator {
                text-transform: initial;
              }
            }

            .amount {
              font-weight: 600;
              font-size: var(--font-14);
              line-height: 21px;
              letter-spacing: -0.02em;
            }


          }
        }
      }
    }

    .stock-stats {

      .stock-stats-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .stats-single {
          width: 100%;
          max-width: 140px;

          label {
            font-weight: 600;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: var(--tertiaryGrayColor);
            margin: 0px;
          }

          .amount {
            font-weight: 600;
            font-size: var(--font-16);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #000;

            .profitLossPercentage {
              font-size: var(--font-12);
              display: inline-block;
              margin-left: 4px;

              img {
                width: 6px;
                vertical-align: initial;

                &.rotate180 {
                  transform: rotate(180deg);
                  vertical-align: middle;
                }
              }

              &.positive {
                color: var(--tertiaryColor);
              }

              &.negative {
                color: var(--errorColor);
              }
            }


          }
        }

        &:nth-child(2) {
          margin-top: 6px;
        }
      }
    }
  }

  .tabs-graph-wrapper {
    padding: 20px 10px;
    box-shadow: 0px 2px 3px #d5d5d5;
    border-radius: var(--borderRadiusL);
    background-color: #fff;
    margin-top: 30px;

    .stats-time-tabs {

      ul {
        margin: 0px;
        padding: 0px;
        display: flex;
        background-color: #F4F5F5;
        border-radius: var(--borderRadiusS);

        li {
          display: inline-block;
          list-style: none;
          width: 100%;
          text-align: center;
          font-weight: 600;
          font-size: var(--font-13);
          line-height: 16px;
          letter-spacing: 0.01em;
          padding: 12px 10px;
          color: var(--tertiaryGrayColor);
          margin: 2px;
          cursor: pointer;

          &.active {
            background-color: #fff;
            color: var(--primaryColor);
            box-shadow: 0px 4px 8px rgba(209, 222, 222, 0.41);
            border-radius: var(--borderRadiusS);
          }
        }
      }
    }

    .selectedTabGraph {
      padding: 24px;

      .d1 {
        text-align: center;

        img {
          width: 400px;
        }
      }

      .bottom-buttons {
        margin-top: 34px;
      }
    }
  }

  // @media screen and (max-width:10000px) and (min-width:1367px) {
  //   width: 730px;
  //   margin: auto;
  // }
}

//INVESTMENT TABS UI
.investment-tabs {
  .nav-tabs {
    border: none;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;

    li {
      margin-right: 20px;
      white-space: nowrap;
          margin-bottom: 6px;

      a.nav-link {
        border: none;
        font-size: var(--font-15);
        line-height: var(--font-20);
        padding: 0px 0px 3px 0px;
        color: var(--tertiaryGrayColor) !important;
        position: relative;

        &.active {
          color: var(--primaryColor) !important;
          background-color: transparent;
        }

        span {
          font-weight: 200;
        }

        &.active::after {
          content: "";
          position: absolute;
          width: 48px;
          background: var(--primaryColor);
          height: 2px;
          bottom: 0px;
          left: 0;
          border-radius: 2px;
        }

        &.buySell.active::after {
          width: 28px;
        }
      }
    }


    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px;
    }


  }

  .tab-content {
    margin-top: 31px;
  }
}

//INVESTMENT TIMELINE
.investment-timeline {
  .timeline-search-box {
    margin-bottom: 31px;
    border-radius: var(--borderRadiusM);
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
    width:343px;

    input {
      border: none;
      padding: 16px 16px 16px 48px;
      height: auto;
      border-radius: var(--borderRadiusM);
      background-color: #f4f5f5;
      border: 1px solid transparent;
      color: #243537;
      transition: all 0.7s;

      &:focus {
        border: 1px solid var(--primaryColor);

        &~svg path {
          fill: var(--primaryColor);
        }
      }

      @media (max-width:575px) {
        height: 54px;
        border-radius: var(--borderRadiusS);
      }
    }

    svg {
      position: absolute;
      top: 21px;
      left: 16px;

      @media (max-width:575px) {
        top: 19px;
      }
    }

    .closeFilter {
      cursor: pointer;
      font-weight: 600;
      font-size: var(--font-14);
      color: var(--primaryColor);
      transition: all 0.5s;
    }

    @media (max-width:1024px) {
      width: 100%;
    }

  }

  .timeline-sorting-dropdown {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .child-listing {
      ul {
        margin: 0px;
        padding: 0px;
        display: flex;

        li {
          width: 38px;
          height: 38px;
          min-width: 38px;
          min-height: 38px;
          background-color: #f4f5f5;
          border-radius: 24px;
          text-align: center;
          overflow: hidden;
          list-style: none;
          line-height: 36px;
          display: inline-block;
          margin-left: 8px;
          cursor: pointer;

          img {}
        }
      }
    }

    select {
      font-weight: 600;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-16);
      line-height: var(--font-20);
      height: var(--font-36);
      color: #243537;
      width: 140px;
      border-radius: var(--borderRadiusM);
      // font-family: 'GothamBold';

      &:focus {
        box-shadow: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: 480px) {
        font-size: var(--font-15);
        height: 36px;
        border-radius: var(--borderRadiusM);
      }
    }
  }

  .pending-history {
    margin-bottom: 40px;

    h4,
    .h4,
    h1,
    .h1,
    h2,
    .h2 {
      font-size: var(--font-16);
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 30px;
      color: var(--primaryColor);

      span.show-all-recent {
        float: right;
        cursor: pointer;
        font-size: var(--font-14);
        font-weight: 600;
        color: var(--tertiaryColor);
      }
    }

    .pending-single {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 40px;

      .avatar {
        width: 48px;
        // height: 48px;
        min-width: 48px;
        // min-height: 48px;
        // background-color: #f4f5f5;
        // border-radius: 24px;
        text-align: center;
        overflow: hidden;
        margin-right: 14px;
      }

      .desc {
        margin-right: 14px;

        h3,
        .h3 {
          font-size: var(--font-16);
          margin-bottom: 0px;
          line-height: 24px;
          font-weight: 300;
          color: #000;
        }

        p {
          font-size: var(--font-14);
          margin-bottom: 0;
        }
      }

      .amount {
        font-size: var(--font-16);
        line-height: 24px;
        color: var(--primaryColor);
        margin-left: auto;

        &.positive {
          color: var(--tertiaryColor);
        }

        &.negative {
          color: var(--errorColor);
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

//INVESTMENT BUY ASSETS
.investment-buy-assets {
  .buy-assets-title {
    font-weight: 700;
    font-size: var(--font-20);
    color: var(--primaryColor);
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    line-height: var(--font-21);

    .routeBack-invest-abs {
      position: absolute;
      left: 0;
      cursor: pointer;
      top: 0px;

      @media (max-width:575px) {
        top: 21px;
      }
    }

    span.port-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: calc(100% - 90px);
    }

    @media (max-width:1024px) {
      margin-bottom: 31px;
    }

    @media (max-width:575px) {
      font-size: var(--font-18);
      line-height: 30px;
      padding-top: 14px;
    }
  }

  .investment-tabs {
    .tab-content {
      .buy-assets-tab-content {
        // width: 600px;

        .form-group {
          label {
            font-weight: 700;
            font-size: var(--font-16);
            line-height: 24px;
            margin-bottom: 5px;
            color: var(--primaryColor);

            @media (max-width:575px) {
              display: block;
              text-align: center;
            }
          }

          input {
            font-size: var(--font-16);
            line-height: 24px;
            background-color: transparent;
            padding: 0px;
            height: 40px;
            border: none;
            border-bottom: 1px solid #243537;
            color: #243537;
            border-radius: 0px;
          }
        }

        .form-group.amount {

          .dollar-input {
            position: relative;

            span.dollar {
              font-size: var(--font-40);
              color: var(--tertiaryGrayColor);
              position: absolute;
              top: 9px;
              line-height: 37.5px;
              font-family: 'GothamXLight';

              &.dollar-active {
                color: $label-highlight;
              }

              @media (max-width:575px) {
                font-size: var(--font-30) !important;
                top: 0;
                line-height: 40px;
              }
            }

            input {
              font-size: var(--font-40) !important;
              line-height: 56px;
              padding: 0px;
              color: #243537;
              height: auto;
              font-family: 'GothamXLight';
              text-overflow: ellipsis;

              &::placeholder {
                color: var(--tertiaryGrayColor);
                font-size: var(--font-40) !important;
                line-height: 56px;
                font-weight: 100;

                @media (max-width:575px) {
                  font-size: var(--font-30) !important;
                  line-height: 40px;
                  text-align: center !important;
                }
              }

              @media (max-width:575px) {
                font-size: var(--font-30) !important;
                line-height: 40px;
                text-align: center !important;
              }
            }
          }
        }

        .sell-receive-column {
          display: flex;
          column-gap: 30px;

          .form-group {
            width: 100%;

            &.share {

              .dollar-input {
                position: relative;

                input {
                  font-size: var(--font-40) !important;
                  line-height: 56px;
                  color: #243537;
                  height: auto;
                  font-family: 'GothamXLight';
                  text-overflow: ellipsis;


                  &::placeholder {
                    color: var(--tertiaryGrayColor);
                    font-size: var(--font-40) !important;
                    line-height: 56px;
                    font-weight: 100;

                    @media (max-width:575px) {
                      font-size: var(--font-30) !important;
                      line-height: 40px;
                      text-align: center;
                    }
                  }

                  @media (max-width:575px) {
                    font-size: var(--font-30) !important;
                    line-height: 40px;
                    padding: 0px;
                    text-align: center;
                  }
                }
              }

              .sell-all {
                span {
                  color: var(--primaryColor);
                  cursor: pointer;

                  @media (max-width:575px) {
                    display: block;
                    text-align: center;
                    font-size: var(--font-14);
                  }
                }
              }
            }

            &.shareValue {

              .dollar-input {
                position: relative;

                .dollar {
                  font-size: var(--font-40);
                  color: var(--tertiaryGrayColor);
                  position: absolute;
                  top: 15px;
                  line-height: 37.5px;
                  font-family: 'GothamXLight';

                  &.active {
                    color: $label-highlight;
                  }

                  @media (max-width:575px) {
                    font-size: var(--font-30) !important;
                    top: 0px;
                    line-height: 40px;
                  }
                }

                input {
                  font-size: var(--font-40) !important;
                  line-height: 56px;
                  color: #243537;
                  height: auto;
                  font-family: 'GothamXLight';
                  padding-left: 0px;
                  pointer-events: none;


                  &::placeholder {
                    color: var(--tertiaryGrayColor);
                    font-size: var(--font-40) !important;
                    line-height: 56px;
                    font-weight: 100;

                    @media (max-width:575px) {
                      font-size: var(--font-30) !important;
                      line-height: 40px;
                      text-align: center;
                    }
                  }

                  @media (max-width:575px) {
                    font-size: var(--font-30) !important;
                    line-height: 40px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .amount-suggestions {
          margin: 32px auto;

          ul {
            padding: 0px;
            margin: 0px;

            li {
              display: inline-block;
              list-style: none;
              font-weight: 400;
              font-size: var(--font-16);
              line-height: 24px;
              text-align: center;
              color: #243537;
              background-color: #F4F5F5;
              border-radius: var(--borderRadiusS);
              padding: 8px 24px;
              margin-right: 12px;
              cursor: pointer;

              &.active {
                background-color: var(--primaryColor);
                color: #fff;
              }
            }
          }
        }

        .available-shares {
          font-weight: 700;
          font-size: var(--font-16);
          line-height: 24px;
          margin: 32px auto;
          color: var(--primaryColor);

          span {
            float: right;
          }
        }

        .available-funds {
          margin-top: 34px;

          label {
            font-weight: 600;
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--primaryColor);
            display: block;
            margin-bottom: 20px !important;

            span {
              float: right;
              color: var(--tertiaryGrayColor);
            }
          }
        }

        .investment-statement {
          font-size: var(--font-14);
        }

        .bottom-fixed-buttons {
          @media (max-width:575px) {
            position: fixed;
            width: 76%;
            bottom: 55px;
            z-index: 9;
            left: 12%;
            padding: 10px 15px 30px;

            button {
              width: 100%;
              padding: 10px 20px !important;
            }
          }
        }

        @media (max-width:575px) {
          width: 100%;
        }
      }
    }
  }

  .investment-buy-assets-preview {
    .preview-options {
      label {
        font-weight: 400;
        font-size: var(--font-16);
        line-height: 24px;
        color: var(--primaryColor);
        display: block;
        margin-bottom: 28px;

        span {
          float: right;
          font-weight: 600;
          color: var(--tertiaryGrayColor);
        }

        &:nth-child(5) {
          font-weight: 600;
        }
      }
    }

    .preview-disclaimer {
      font-weight: 400;
      font-size: var(--font-12);
      line-height: 20px;
      color: var(--tertiaryGrayColor);
      margin-top: 40px;
      text-align: center;

      a {
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: 76%;
        bottom: 55px;
        z-index: 9;
        left: 12%;
        padding: 10px 15px 30px;

        button {
          width: 100%;
          padding: 10px 20px !important;
        }
      }
    }
  }
}

//INVESTENT ORDER SUCCESS
.investment-buy-assets-success {
  .successMarkBox {
    width: 152px;
    height: 152px;
    padding: 16px;
    border-radius: 76px;
    margin: 100px auto 0px;

    .checkcover {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background: var(--primaryColor);
      line-height: 120px;
      text-align: center;

      img {
        width: 56px;
      }
    }
  }

  .successText {
    margin-top: 24px;
    text-align: center;

    h3,
    .h3 {
      font-weight: 600;
      font-size: var(--font-22);
      line-height: 23px;
      letter-spacing: 0.01em;
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: calc(100% - 30px);
      bottom: 0px;
      padding-top: 10px;
      z-index: 99;
      padding-bottom: 10px;
      left: 15px;
    }
  }

  @media (max-width:575px) {
    margin: 200px auto 0px;
  }
}

//INVESTMENT RESEARCH LISTING
.investment-research-wrapper {
  .research-title {
    font-weight: 700;
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.back {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }

  .what-things-care {
    p {
      font-weight: 600;
      font-size: var(--font-16);
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #243537;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  .investment-research-cards {
    p {
      font-weight: 600;
      font-size: var(--font-16);
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #243537;
      text-align: center;
      margin-bottom: 24px;
    }

    .research-cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;

      .card-single {
        padding: 28px 26px;
        border-radius: var(--borderRadiusS);
        margin-bottom: 16px;
        text-align: center;
        cursor: pointer;
        width: calc(24% - 7px);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        height: 90px;

        p {
          font-weight: 700;
          font-size: var(--font-14);
          color: #fff;
          margin: 0px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

      }
    }

    @media (max-width:1200px) {
      .research-cards-wrapper {
        .card-single {
          width: calc(24% - 9px);
        }
      }
    }

    @media (max-width:1024px) {
      .research-cards-wrapper {
        .card-single {
          width: calc(24% - 10px);
        }
      }
    }

    @media (max-width:767px) {
      .research-cards-wrapper {
        .card-single {
          width: calc(24% - 8px);
        }
      }
    }

    @media (max-width:575px) {
      .research-cards-wrapper {
        .card-single {
          width: calc(50% - 10px);
        }
      }
    }
  }
}

//INVESTMENT RESEARCH INDUSTRY
.investment-research-industry-wrapper {
  .research-title {
    font-weight: 700;
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.back {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }

  .routeBack {
    display: none;

    @media (max-width:575px) {
      display: block;
      position: absolute;
      top: 12px;
      z-index: 9;
      left: 0px;
    }
  }

  .industry-search-box {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 31px;
    margin-top: 5px;

    .searchBox {
      border-radius: var(--borderRadiusM);
      position: relative;
      width: 343px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      input {
        border: none;
        padding: 16px 16px 16px 48px;
        height: auto;
        border-radius: var(--borderRadiusM);
        background-color: #f4f5f5;
        border: 1px solid transparent;
        transition: all 0.7s;

        &:focus {
          border: 1px solid var(--primaryColor);
        }
      }

      svg {
        position: absolute;
        top: 21px;
        left: 16px;

        @media (max-width:575px) {
          top: 23px;
        }
      }

      .closeFilter {
        cursor: pointer;
        font-weight: 600;
        font-size: var(--font-14);
        color: var(--primaryColor);
        transition: all 0.5s;
      }

      @media (max-width:1024px){
        width:100%;
      }

      @media(max-width:575px) {
        border-radius: var(--borderRadiusS);

        input {
          height: 60px;
        }

        img.searchIcn {
          top: 15px;
        }

        img.closeFilter {
          top: 17px;
          width: 12px;
        }
      }
    }

    @media (max-width:1024px) {
      margin-top: 0px;
      width:100%;
    }

    @media (max-width:575px) {
      padding-bottom: 21px;
    }
  }

  .industry-sorting-dropdown {
    padding-bottom: 16px;

    select {
      font-weight: 600;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-16);
      line-height: var(--font-20);
      height: var(--font-36);
      color: #243537;
      width: 140px;
      border-radius: var(--borderRadiusM);
      // font-family: 'GothamBold';

      &:focus {
        box-shadow: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: 480px) {
        font-size: var(--font-15);
        height: 36px;
        border-radius: var(--borderRadiusM);
      }

      @media (max-width:320px) {
        width: 130px;
      }
    }

    @media (max-width:575px) {
      padding-bottom: 26px;
    }
  }

  .sticky-header {
    @media (max-width:575px) {
      position: sticky;
      top: 40px;
      z-index: 9;
      margin-left: -15px;
      margin-right: -15px;
      padding: 0 15px;
    }
  }

  .investment-portfolios {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    .investment-portfolio-card {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      border-radius: var(--borderRadiusL);
      display: flex;
      align-items: center;
      padding: 14px;
      margin-bottom: 20px;
      cursor: pointer;
      background-color: #fff;
      width: calc(50% - 15px);
        
      @media (max-width:820px) {
        width: 100%;
        margin-bottom: 12px;
      }

      &.card-disable {
        background-color: #ececec;
      }

      .portfolio-logo {
        width: 40px;
        height: 40px;
        min-height: 37px;
        min-width: 37px;
        // background: #F4F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;

        img {
          // width: 15px;
          // filter: grayscale(1);
        }
      }

      .portfolio-details {
        width: 100%;

        .first-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;

          .company {
            font-size: var(--font-14);
            line-height: 21px;
            letter-spacing: -0.02em;
            color: #243537;
            overflow: hidden;
            font-weight: 700;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            @media (max-width:480px) {
              font-size: var(--font-14);
            }
          }

          .amount {
            font-weight: 600;
            font-size: var(--font-14);
            line-height: 21px;
            letter-spacing: -0.02em;
            color: $theme-green;
            padding-left: 15px;

            @media (max-width:480px) {
              font-size: var(--font-14);
            }
          }
        }

        .bottom-row {
          display: flex;
          align-items: center;

          .symbol {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: $black-1;
            margin-bottom: 0px;
            white-space: nowrap;
            text-transform: uppercase;

            span.watchlist-indicator {
              text-transform: initial;
            }

            @media (max-width:480px) {
              line-height: 21px;
            }
          }

          .graph-box {
            position: relative;
            margin-left: auto;
            margin-right: 80px;

            canvas {
              width: 90px !important;
              height: 24px !important;

              @media (max-width:480px) {
                height: 21px !important;
              }
            }

            .line-stroke {
              width: 100%;
              position: absolute;
              top: calc(50% - 1px);
            }

            @media (max-width:480px) {
              margin-right: 30px;
            }
          }

          .profitLossPercent {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-bottom: 0;
            text-align: right;

            &.positive {
              color: var(--tertiaryColor);
            }

            &.negative {
              color: var(--errorColor);
            }

            @media (max-width:480px) {
              line-height: 21px;
            }
          }
        }
      }
    }

    &.reseach-landing-cards {
      .investment-portfolio-card {
        .portfolio-amount {
          width: 100px;
        }

        .portfolio-graph {
          width: 120px;
          min-width: 120px;
        }

        .portfolio-name {
          width: calc(100% - 257px);
        }
      }
    }
  }
}

//INVESTMENT WATCHLIST CSS
.investment-watchlist {
  .watchlist-title {
    font-weight: 700;
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.back {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }

  .watchlist-search-box {
    margin-bottom: 26px;
    border-radius: var(--borderRadiusM);
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
    width:343px;

    input {
      border: none;
      padding: 16px 16px 16px 48px;
      height: auto;
      border-radius: var(--borderRadiusM);
      background-color: #f4f5f5;
      border: 1px solid transparent;
      color: #243537;
      transition: all 0.7s;

      &:focus {
        border: 1px solid var(--primaryColor);

        &~svg path {
          fill: var(--primaryColor);
        }
      }
    }

    svg {
      position: absolute;
      top: 21px;
      left: 16px;
    }

    .closeFilter {
      cursor: pointer;
      font-weight: 600;
      font-size: var(--font-14);
      color: var(--primaryColor);
      transition: all 0.5s;
    }

    @media (max-width:1024px) {
      width: 100%;
    }

    @media (max-width:575px) {
      input {
        height: 60px;
        border-radius: var(--borderRadiusS);
      }

      svg {
        top: 23px;
      }
    }
  }

  .watchlist-sorting-dropdown {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      font-weight: 700;
      font-size: var(--font-16);
      color: #243537;
      line-height: 18px;
      margin: 0px;
    }

    select {
      font-weight: 500;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-16);
      line-height: var(--font-20);
      height: var(--font-36);
      color: #243537;
      width: 140px;
      // font-family: 'GothamBold';

      &:focus {
        box-shadow: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: 480px) {
        font-size: var(--font-13);
        height: 32px;
      }
    }
  }

  .watchlist-portfolios {
    .all-watchlist {
      display: flex;
      font-size: var(--font-16);
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 26px;
      margin-top: 0px;
      color: var(--primaryColor);
      position: relative;
    }

    .watchlist-portfolio-card {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      border-radius: var(--borderRadiusL);
      display: flex;
      align-items: center;
      padding: 14px;
      margin-bottom: 16px;
      cursor: pointer;
      background-color: #fff;

      .portfolio-logo {
        width: 40px;
        height: 40px;
        // background: #F4F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;

        img {
          // width: 15px;
          // filter: grayscale(1);
        }
      }

      .portfolio-details {
        width: 100%;

        .first-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;

          .company {
            font-size: var(--font-16);
            line-height: 21px;
            letter-spacing: -0.02em;
            color: #243537;
            overflow: hidden;
            font-weight: 700;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            @media (max-width:480px) {
              font-size: var(--font-14);
            }
          }

          .amount {
            font-weight: 600;
            font-size: var(--font-16);
            line-height: 21px;
            letter-spacing: -0.02em;
            color: $theme-green;
            padding-left: 15px;

            @media (max-width:480px) {
              font-size: var(--font-14);
            }
          }
        }

        .bottom-row {
          display: flex;
          align-items: center;

          .symbol {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: $black-1;
            margin-bottom: 0px;
            white-space: nowrap;
            text-transform: uppercase;

            span.watchlist-indicator {
              text-transform: initial;
            }

            @media (max-width:480px) {
              line-height: 21px;
            }
          }

          .graph-box {
            position: relative;
            margin-left: auto;
            margin-right: 80px;

            canvas {
              width: 90px !important;
              height: 24px !important;

              @media (max-width:480px) {
                height: 21px !important;
              }
            }

            .line-stroke {
              width: 100%;
              position: absolute;
              top: calc(50% - 1px);
            }

            @media (max-width:480px) {
              margin-right: 30px;
            }
          }

          .profitLossPercent {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-bottom: 0;
            text-align: right;

            &.positive {
              color: var(--tertiaryColor);
            }

            &.negative {
              color: var(--errorColor);
            }

            @media (max-width:480px) {
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

//INVESTMENT SETTINGS
.investment-settings {
  .setting-title {
    font-weight: 700;
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.back {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }

  .settings-single {
    padding: 15px 0px;
    // border-bottom: 1px solid #F4F5F5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3,
    .h3 {
      font-size: var(--font-16);
      line-height: 24px;
      color: var(--primaryColor);
      margin-bottom: 0px;

      img {
        width: 21px;
        margin-left: 10px;
        vertical-align: middle;
        margin-top: -2px;
        cursor: pointer;
      }
    }

    .custom-switch {
      padding-left: 1.75rem;

      label {
        &::before {
          left: -1.75rem;
        }

        &::after {
          left: calc(-1.75rem + 2px);
        }
      }
    }

    .limit-buttons {
      .inputGrp {
        position: relative;
        width: 100px;

        span {
          position: absolute;
          left: 0;
          top: 1px;
          color: var(--tertiaryGrayColor);
        }

        input {
          text-align: right;
          height: auto;
          padding: 0;
        }
      }
    }

    &:nth-child(2) {
      padding-top: 0px;
    }
  }
}

//INVESTMENT REPORTS
.page-parent-investments-reports {
  .investment-reports {
    .report-table {
      .statementCardWrapper {
        .statementSingle {
          background-color: #c3e3e0;
        }
      }
    }
  }
}

.investment-reports {
  .report-title {
    font-weight: 700;
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.back {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }

  .total-amount {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    .amount {
      text-align: center;
      margin-left: calc(50% - 62px);

      h3,
      .h3 {
        font-weight: 100;
        font-size: var(--font-36);
        line-height: 54px;
        color: #0A0A0A;
        margin-bottom: 0px;
      }

      p {
        font-weight: 400;
        line-height: 21px;
        font-size: var(--font-14);
        color: var(--tertiaryGrayColor);
        margin-bottom: 0px;
      }
    }

    select {
      font-weight: 500;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-16);
      line-height: var(--font-20);
      height: var(--font-36);
      color: #243537;
      width: 140px;
      margin-left: auto;
      margin-top: 10px;

      &:focus {
        box-shadow: none;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    @media (max-width:480px) {
      .amount {
        margin-left: 0;

        h3,
        .h3 {
          font-size: var(--font-35);
        }
      }

      select {
        font-size: var(--font-13);
        height: 32px;
        margin-top: 7px;
      }
    }

    @media (max-width:375px) {
      .amount {

        h3,
        .h3 {
          font-size: var(--font-30);
          line-height: 40px;
        }
      }
    }
  }

  .report-profit-loss {
    display: flex;
    margin-top: 24px;
    margin-bottom: 35px;
    justify-content: center;

    .profit {
      padding-right: 32px;

      h3,
      .h3 {
        font-weight: 500;
        font-size: var(--font-14);
        line-height: 24px;
        letter-spacing: -0.02em;
        margin-bottom: 8px;
        color: var(--primaryColor);
        position: relative;
        padding-left: 16px;

        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: var(--primaryColor);
          left: 0;
          top: 8px;
        }
      }

      p {
        font-weight: 400;
        font-size: var(--font-24);
        line-height: 23px;
        color: #0A0A0A;
      }
    }

    .loss {
      padding-left: 32px;
      border-left: 1px solid #D3D7D7;

      h3,
      .h3 {
        font-weight: 500;
        font-size: var(--font-14);
        line-height: 24px;
        letter-spacing: -0.02em;
        margin-bottom: 8px;
        color: var(--primaryColor);
        position: relative;
        padding-left: 16px;

        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: #F24405;
          border-radius: 4px;
          left: 0;
          top: 8px;
        }
      }

      p {
        font-weight: 400;
        font-size: var(--font-24);
        line-height: 23px;
        color: #0A0A0A;
      }
    }
  }

  .report-table {
    .heading {

      h3,
      .h3 {
        font-weight: 700;
        font-size: var(--font-16);
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #243537;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: var(--primaryColor);
          font-weight: 400;
          font-size: var(--font-16);
          line-height: 24px;
          cursor: pointer;
        }
      }
    }

    h3.yearName,
    .yearName,
    .h3.yearName,
    .yearName {
      font-size: var(--font-13);
      font-weight: 500;
      margin-bottom: 15px;
      color: var(--secondaryGrayColor);
      background: #f4f5f5;
      padding: 9px 12px;

      @media (max-width:575px) {
        border-radius: 0px;
        padding: 3px 15px;
        margin-bottom: 0;
      }
    }

    .statementCardWrapper {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2%;
      margin-bottom: 20px;

      .noChild-addNewOne {
        width: 100%;
        margin-top: 50px;
      }

      .statementSingle {
        display: flex;
        align-items: center;
        padding: 16px;
        border-radius: 24px;
        margin-bottom: 10px;
        box-shadow: 0px 4px 8px #d1dede69;
        width: 32%;

        .sDate {
          min-width: 150px;
          font-size: var(--font-14);
          margin-bottom: 0px;
          color: #000;
          line-height: 16px;
          letter-spacing: 0.01em;
          white-space: nowrap;
          font-weight: 500;

          @media (max-width:480px) {
            min-width: unset;
          }
        }

        .eDate {
          width: 200px;
          min-width: 200px;
          font-size: var(--font-12);
          margin-bottom: 0px;
          color: var(--tertiaryGrayColor);
          line-height: 16px;
          letter-spacing: 0.01em;
          margin-right: 50px;
          white-space: nowrap;
        }

        .pdf {
          width: 100%;
          text-align: right;

          img {
            margin-top: -3px;
          }
        }

        h4,
        .h4 {
          color: var(--primaryColor);
          margin: 0px;
          font-size: var(--font-14);
          line-height: 22px;
        }

        @media (max-width:575px) {
          box-shadow: none;
          margin-bottom: 0;
          border-radius: 0;
          background-color: $parent-investment-bg1;
          border-bottom: 1px solid #fff;
          padding: 10px 15px;

          &:last-child {
            border-bottom: none;
          }
        }

        @media (max-width:480px) {
          width: 100%;
        }
      }

      @media (max-width:575px) {
        margin-bottom: 0;
      }
    }

    @media (max-width:575px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

@media screen and (max-width:10000px) and (min-width:1367px) {

  .investment-dashboard,
  .investment-portfolio-detail-wrapper,
  .investment-research-industry-wrapper,
  .investment-research-wrapper,
  .investment-buy-assets,
  .investment-watchlist {
    // width: 730px;
    margin: auto;
  }

  .page-parent-investments-watchlist {
    .loader-div {
      max-width: 730px;
      margin-left: 105px;
    }
  }

  .investment-research-wrapper {
    .investment-research-cards {
      .research-cards-wrapper {
        .card-single {
          width: calc(23% - 7px);
        }
      }
    }
  }

  .investment-buy-assets {
    .investment-tabs {
      .tab-content {
        .buy-assets-tab-content {
          width: 100%;
        }
      }
    }
  }
}

.portfolio-dotted {
  border-bottom: thin red dotted;
  bottom: 14px;
  position: relative;
}

//PARENT MODULE INVESTMENT IN PRACTICE MODE
.parent-module {
  .investment-page-title {
    color: var(--primaryColor);
    background-color: inherit;

    span {
      &.watchlist-icon {
        svg {
          path {
            stroke: var(--tertiaryGrayColor);
          }
        }

        &.highlighted {
          svg {
            path {
              fill: var(--primaryColor);
              stroke: var(--primaryColor);
            }
          }
        }
      }
    }
  }
}

//CHILD MODULE INVESTMENT  IN PRACTICE MODE
.child-module {
  .investment-page-title {
    color: var(--primaryColor);
    background-color: #fdeddf;

    span {
      &.watchlist-icon {
        &.highlighted {
          svg {
            path {
              fill: var(--primaryColor);
              stroke: var(--primaryColor);
            }
          }
        }
      }
    }
  }

  .investment-buy-assets {
    .buy-assets-title {
      color: var(--primaryColor);
    }
  }

  .investment-tabs {
    .nav-tabs {
      li {
        a {
          &.nav-link {
            &.active {
              &::after {
                background-color: var(--primaryColor);
              }
            }
          }
        }
      }
    }
  }

  .investment-reports {
    .report-table {
      .statementCardWrapper {
        .statementSingle {
          background-color: #f3dac4;

          .sDate {
            color: #000;
          }
        }
      }
    }
  }

  .investment-portfolio-detail-wrapper {
    .tabs-graph-wrapper {
      .stats-time-tabs {
        ul {
          li {
            &.active {
              color: var(--primaryColor);
            }
          }
        }
      }
    }
  }
}

.page-child-investments-dashboard {
  .investment-dashboard {
    .investment-amount-display {
      .total-amount {
        .limits-row {
          .buying-limit {

            h3,
            .h3 {
              color: var(--primaryColor);
            }
          }
        }
      }
    }
  }

}

.dashboard-wrapper.child-module.practice-mode {
  .investment-page-title {
    color: var(--primaryColor);

    // @media (max-width:575px) {
    //   background-color: $child-investment-bg;
    // }
  }

  .industry-search-box {
    .searchBox {
      input {
        background-color: #fff;
      }
    }
  }

  .industry-sorting-dropdown {
    select {
      background-color: #fff;
    }
  }

}