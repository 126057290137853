$child-theme : #F2921D;
$yellow : #f2c94c;
$grey-2 : #f4f5f5;
$grey-3 : #d4d5d5;
$grey-4 : #7C8687;
$black : #000000;
$black-1 : #424242;
$theme-green : #025159;
$label-highlight : #243537;
$child-investment-bg : #f2e6da;

.fill-child-theme {
  fill: var(--primaryWardColor);
}

.child-left-sidebar {
  min-width: 243px;
  width: 244px;
  // padding-top: 3px;
  position: fixed;
  left: 40px;
  transition: all 0.5s;

  .toggleSidebar {
    display: none;
    position: fixed;
  }

  ul {
    padding: 0px;

    li {
      list-style: none;
      font-size: var(--font-13);
      margin-bottom: 28px;
      font-weight: 400;
      color: var(--tertiaryGrayColor);
      letter-spacing: 0.4px;
      cursor: pointer;

      a {
        text-decoration: none;
        color: var(--tertiaryGrayColor) !important;

        span.menu-icon {
          width: 40px;
          display: inline-block;

          img {
            width: 25px;
          }
        }

        span {
          line-height: 11px;
          display: inline-block;
          vertical-align: middle;
        }

        svg {
          width: 30px;
        }
      }

      &.active {
        a {
          color: var(--primaryWardColor) !important;
        }

        svg {
          path {
            stroke: var(--primaryWardColor);
          }

          circle {
            stroke: var(--primaryWardColor);
          }

          rect {
            fill: var(--primaryWardColor);
          }

          &#myFamilyIcon {
            path {
              fill: none;
            }

            path#childHead {
              fill: var(--primaryWardColor);
            }

            path#lip {
              stroke: var(--primaryWardColor);
            }

            ellipse#eye {
              fill: var(--primaryWardColor);
            }

            path#stroke {
              stroke: var(--primaryWardColor);
            }
          }

          &#investmentReport {
            path {
              fill: var(--primaryWardColor);
              stroke: none;
            }
          }

          &#editProfile {
            path {
              fill: var(--primaryWardColor);
              stroke: none;
            }
          }
        }
      }

      &.qr-code-menu {
        &.active {
          svg {
            path {
              fill: var(--primaryWardColor);
              stroke: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 10000px) and (min-width: 1367px) {
    left: calc(50% - 550px);
  }

  @media (max-width:768px) {
    left: 15px;
    width: 200px;
    min-width: 200px;
  }

  @media (max-width:767px) {
    display: none;
    left: -240px;

    &.active {
      left: 0px;
      background: #fff;
      box-shadow: 2px 8px 8px #d1dede69;
      z-index: 9;
      height: 100%;
      padding-left: 20px;
      top: 54px;
      padding-top: 30px;
    }
  }
}

//PAGE : CHILD DASHBOARD
.page-child-dashboard {
  .dashboard-heading {
    display: none !important;
  }
}

.child-dashboard {
  .child-dashboard-breadcrumb {
    padding-bottom: 31px;

    .buttons-group {
      display: flex;
      align-items: flex-end;

      img {
        margin-right: 20px;
      }

      .pay-peers {
        margin-left: auto;
        font-size: var(--font-15);
        line-height: 20px;
        color: var(--primaryWardColor);
        white-space: nowrap;
      }

      @media (max-width:575px) {
        align-items: center;
      }
    }

    @media (max-width:575px) {
      position: sticky;
      background: #fff;
      z-index: 9;
      top: 0px;
      margin-left: -15px;
      margin-right: -15px;
      padding: 21px 15px 20px;
      margin-bottom: 11px;
    }
  }

  .message-chores-due {
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 0px;
    border-radius: var(--borderRadiusM);
    background: var(--primaryWardColor);
    color: #fff;
    position: relative;

    .close-card {
      position: absolute;
      right: 7px;
      top: 4px;

      svg {
        width: 19px;
        cursor: pointer;
      }
    }

    p {
      margin: 0;
      font-size: var(--font-16);

      @media (max-width:575px) {
        font-weight: 600;
        font-size: var(--font-14);
        line-height: 19px;
      }
    }
  }

  .virtual-card {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 20px;

    .card-wrapper {
      width: 320px;
      margin: auto;
      position: relative;

      .view-card-button {
        width: 27px;
        height: 27px;
        border-radius: 14px;
        background-color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        left: 20px;

        span {
          width: 3px;
          height: 3px;
          border-radius: 3px;
          background-color: #fff;
        }
      }

      .card-frozen-error {
        width: 27px;
        height: 27px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        left: 20px;

        &.red {
          background-color: var(--errorColor);
        }

        &.child-theme {
          background-color: var(--primaryWardColor);
        }

        img {
          width: 18px;
          margin-left: -1px;
        }
      }

      .card-number {
        position: absolute;
        top: 90px;
        color: #fff;
        left: 20px;
      }

      img {
        width: 100%;
      }

      @media (max-width:480px) {
        width: 290px;
      }
    }
  }

  .available-balance {
    text-align: center;

    .total-amount {
      font-size: var(--font-40);
      line-height: 56px;
      color: $black;
      font-weight: 100;
    }

    .text-balance {
      font-size: var(--font-13);
      line-height: 16px;
      letter-spacing: 0.01em;
      cursor: pointer;
      color: var(--tertiaryGrayColor);
    }
  }

  .allowance-top{
      line-height: 36px;
      cursor: pointer;
      color: var(--tertiaryGrayColor);
      font-size: var(--font-16);
      margin-top:20px;
      text-align: center;

      .allowanceAmtField {
        position: relative;
        padding-right: 28px;
        padding-left: 10px;

        &::after {
          content: '';
          position: absolute;
          right: 12px;
          width: 5px;
          height: 5px;
          background: var(--tertiaryGrayColor);
          border-radius: 3px;
          top: 7px;

          @media (max-width:575px) {
            right: 7px;
          }
        }

        @media (max-width: 480px) {
          padding-right: 18px;
        }
      }

      .allowanceFrequency {
        position: relative;
        padding-right: 24px;

        &::before {
          content: '';
          position: absolute;
          right: 9px;
          width: 5px;
          height: 5px;
          background: var(--tertiaryGrayColor);
          border-radius: 3px;
          top: 7px;

          @media (max-width:575px) {
            display: none;
          }
        }

        @media (max-width:575px) {
          padding-right: 0;
        }
      }

      .allowance-label {
        color: var(--primaryWardColor);
        font-weight: 600;
      }

      .allowanceWeekMonthDays {
        @media (max-width:575px) {
          display: block;
          padding-top: 3px;
        }
      }

      @media (max-width: 991px) {
        font-size: var(--font-14);
      }

      @media (max-width: 768px) {
        font-size: var(--font-15);
        line-height: 19px;
      }

      @media (max-width: 480px) {
        font-size: var(--font-15);
        width: 100%;
      }
  }

  .stripes-wrapper {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
      row-gap: 15px;

    .stripe-single {
      display: flex;
      width: calc(50% - 10px);
      align-items: center;
      justify-content: space-between;
      padding: 18px 16px;
      background: #fafafa;
      border-radius: var(--borderRadiusM);
      // box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      cursor: pointer;

      .label {
        vertical-align: middle;
        font-size: var(--font-16);
        line-height: 24px;
        color: var(--primaryWardColor);
        font-weight: 600;
        letter-spacing: -0.02em;
        white-space: nowrap;

        @media (max-width:575px) {
          font-size: var(--font-15);
        }
      }

      .amount {
        color: #243537;
        font-size: var(--font-16);
        line-height: 26px;
        font-weight: 600;
        width: 100%;
        text-align: right;
        margin-top: 1px;

        @media (max-width:575px) {
          font-size: var(--font-15);
        }
      }

      .right-angle {
        margin-left: 15px;

        img {
          width: 8px;
          margin-right: 0;
        }

        @media (max-width:575px) {
          display: initial;
        }
      }

      @media (max-width:767px) {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.27);
        padding: 14px 16px;
        border-radius: var(--borderRadiusS);
      }

      @media (max-width:575px){
        width:100%;
        background-color: #fff;
      }
    }
  }

  .allowance-detail {
    padding: 60px 0px 30px;
    text-align: center;
    position: relative;

    .chartFrame {
      position: absolute;

      select {
        width: 150px;
        min-width: 150px;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: 19px;
        font-weight: 600;
        height: 36px;
        color: $label-highlight;
        border-radius: var(--borderRadiusM);

        &:last-child {
          margin-right: 0px;
        }

        &:focus {
          box-shadow: none;
        }

        @media (max-width: 480px) {
          width: auto;
          min-width: 120px;
          font-size: var(--font-14);
          height: 32px;
        }
      }

      @media (max-width: 991px) {
        margin-right: 20px;
        position: relative;
      }

      @media (max-width:575px) {
        display: none;
      }

    }

    span {
      line-height: 36px;
      cursor: pointer;
      color: var(--tertiaryGrayColor);
      font-size: var(--font-16);

      &.allowanceAmtField {
        position: relative;
        padding-right: 28px;
        padding-left: 10px;

        &::after {
          content: '';
          position: absolute;
          right: 12px;
          width: 5px;
          height: 5px;
          background: var(--tertiaryGrayColor);
          border-radius: 3px;
          top: 7px;

          @media (max-width:575px) {
            right: 7px;
          }
        }

        @media (max-width: 480px) {
          padding-right: 18px;
        }
      }

      &.allowanceFrequency {
        position: relative;
        padding-right: 24px;

        &::before {
          content: '';
          position: absolute;
          right: 9px;
          width: 5px;
          height: 5px;
          background: var(--tertiaryGrayColor);
          border-radius: 3px;
          top: 7px;

          @media (max-width:575px) {
            display: none;
          }
        }

        @media (max-width:575px) {
          padding-right: 0;
        }
      }

      &.allowance-label {
        color: var(--primaryWardColor);
        font-weight: 600;
      }

      &.allowanceWeekMonthDays {
        @media (max-width:575px) {
          display: block;
          padding-top: 3px;
        }
      }

      @media (max-width: 991px) {
        font-size: var(--font-14);
      }

      @media (max-width: 768px) {
        font-size: var(--font-15);
        line-height: 19px;
      }

      @media (max-width: 480px) {
        font-size: var(--font-15);
        width: 100%;
      }
    }

    p {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      cursor: pointer;

      span {
        font-weight: 300;
        color: var(--tertiaryGrayColor);
        font-size: var(--font-14);
      }
    }

    @media (max-width: 991px) {
      display: flex;
      align-items: center;
    }

    @media (max-width: 575px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 20px 0px 30px !important;
    }
  }

  .displayChart {
    margin-bottom: 50px;

    .chartGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .pieChart {
      width: 279px;
      height: 279px;
      position: relative;

      .chartCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
          text-align: center;
          color: $grey-4;
          font-size: var(--font-20);
          line-height: 26px;
          margin-bottom: 3px;

          @media (max-width:575px) {
            font-family: 'GothamXLight';
          }
        }

        h3,.h3 {
          font-size: var(--font-22);
          font-weight: 700;
          margin-bottom: 0;
        }

        h4.selected-timeframe,.h4.selected-timeframe {
          // display: none;
          font-size: var(--font-13);
          margin-top: 4px;
          margin-bottom: 0;
          color: var(--tertiaryGrayColor);

          @media (max-width:575px) {
            display: inline-block;
          }
        }
      }

      @media (max-width:480px) {
        width: 279px !important;
        height: 279px !important;
      }
    }

    .mobile-chart-frame-select {
      display: none;

      .chartFrame {

        select {
          width: 150px;
          min-width: 150px;
          background-color: $grey-2;
          border: none;
          font-size: var(--font-16);
          line-height: 19px;
          font-weight: 600;
          height: 36px;
          color: $label-highlight;

          &:last-child {
            margin-right: 0px;
          }

          &:focus {
            box-shadow: none;
          }

          @media (max-width:575px) {
            font-weight: 600;
            border-radius: var(--borderRadiusM);
            height: 42px;
          }
        }
      }

      @media (max-width:575px) {
        display: block;
        width: 100%;
        margin-top: 40px;
        text-align: center;
      }
    }

    .chartLegends {
      width: 100%;
      text-align: center;
      margin-top: 40px;

      ul {
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;

        li {
          list-style: none;
          text-align: center;
          min-width: 80px;
          padding-right: 15px;

          .legImg {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 480px) {
              width: 48px;
              height: 48px;
              border-radius: 48px;
              line-height: 45px;
            }
          }

          img {
            width: 22px;
          }

          p {
            color: #243537;
            margin-top: 10px;
            font-size: var(--font-14);
            font-weight: 600;

            @media (max-width: 480px) {
              margin-top: 3px;
              font-size: var(--font-12);
              margin-bottom: 0px;
            }
          }

          &.savings {
            .legImg {
              background-color: #f27405;
            }
          }

          &.wallet {
            .legImg {
              background-color: $theme-green;
            }
          }

          &.spending {
            .legImg {
              background-color: #f24405;
            }
          }

          &.charity {
            .legImg {
              background-color: var(--tertiaryColor);
            }
          }

          &.goals {
            .legImg {
              background-color: $grey-4;
            }
          }

          &.transfers {
            .legImg {
              background-color: #d3d7d7;
            }
          }

          &.investment {
            .legImg {
              background-color: #F47C52;
            }
          }

          @media (max-width: 480px) {
            min-width: 60px;
            margin-bottom: 20px;
            width: 33%;

            img {
              width: 24px;
            }
          }
        }
      }

      @media (max-width:575px) {
        margin-top: 30px;
      }
    }

    @media (max-width:575px) {
      margin-bottom: 20px;
    }
  }

  .financial-targets {
    margin-bottom: 50px;

    h3,.h3 {
      font-size: var(--font-24);
      line-height: 36px;
      color: $theme-green;
      font-weight: 600;

      @media (max-width:575px) {
        font-size: var(--font-16);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 23px;
      }
    }

    .financial-stripe {
      margin-bottom: 16px;

      label {
        font-weight: 700;
        font-size: var(--font-12);
        line-height: 16px;
        letter-spacing: 0.01em;
        color: $theme-green;
        margin-bottom: 4px;

        @media (max-width:575px) {
          font-size: var(--font-12);
        }
      }

      .bar-group {
        position: relative;
        width: calc(100% - 30px);

        .baseBar {
          background: $grey-2;
          border-radius: 4px;
          width: 100%;
          height: 8px;
        }

        .dynamicBar {
          width: 20%;
          height: 8px;
          border-radius: 4px;
          position: absolute;
          top: 0;
          max-width: 100%;
          transition: all 1s;
        }

        .growth-indicator {
          position: absolute;
          right: -30px;
          top: 0;
          height: 8px;
          width: 20px;
          display: flex;
          align-items: center;

          img {
            vertical-align: top;
          }
        }
      }

      &:nth-child(2) {
        .bar-group {
          .dynamicBar {
            background: var(--primaryWardColor)
          }
        }
      }

      &:nth-child(3) {
        .bar-group {
          .dynamicBar {
            background: #F24405;
          }
        }
      }

      &:nth-child(4) {
        margin-bottom: 0px;

        .bar-group {
          .dynamicBar {
            background: $theme-green;
          }
        }
      }
    }
  }

  .savings-chart {
    margin-bottom: 50px;

    h3,.h3 {
      font-size: var(--font-24);
      line-height: 36px;
      color: $theme-green;
      font-weight: 600;
      display: flex;
      align-items: center;

      span.last-time-frame {
        font-size: var(--font-14);
        margin-left: 10px;
      }

      @media (max-width:575px) {
        font-size: var(--font-16);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 23px;
      }
    }
  }

  .spending-chart {
    h3,.h3 {
      font-size: var(--font-24);
      line-height: 36px;
      color: $theme-green;
      font-weight: 600;
      display: flex;
      align-items: center;

      span.last-time-frame {
        font-size: var(--font-14);
        margin-left: 10px;
      }

      @media (max-width:575px) {
        font-size: var(--font-16);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 23px;
      }
    }
  }

  @media (max-width:575px) {
    padding-bottom: 30px;
  }

}


//PAGE : CHORES AND GOALS
.page-child-chores {
  .dashboard-heading {
    display: none !important;
  }

  .chores-goal {
    .top-buttons-group {
      padding-bottom: 31px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pay-peers {
        margin-left: auto;
        font-size: var(--font-15);
        color: var(--primaryWardColor);
        white-space: nowrap;
      }

      @media (max-width:575px) {
        padding-top: 21px;
        padding-bottom: 20px;
        position: sticky;
        z-index: 9;
        background-color: #fff;
        top: 0;
      }
    }

    .nav-tabs {
      @media (max-width:575px) {
        position: sticky;
        top: 66px;
        background: #fff;
        z-index: 9;
      }
    }

    .tab-content {
      @media (max-width:575px) {
        padding-top: 0 !important;
      }
    }

    .chores-tab-wrapper {
      .filter-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter {
          select {
            width: 130px;
            background-color: $grey-2;
            border: none;
            margin-right: 10px;
            font-size: var(--font-16);
            line-height: 19px;
            font-weight: 600;
            height: auto;
            padding: 10px 12px;
            color: $label-highlight;
            border-radius: var(--borderRadiusM);

            &:focus {
              box-shadow: none;
            }
          }
        }

        @media (max-width:575px) {
          position: sticky;
          top: 103px;
          background: #fff;
          z-index: 9;
          padding-top: 29px;
          padding-bottom: 20px;
        }
      }

      .chores-listing {
        margin-top: 30px;

        .chore-row {
          margin-bottom: 40px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          cursor: pointer;

          .icon-box {
            width: 35px;
            min-width: 35px;
            margin-right: 16px;

            .s-box {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              margin-right: 10px;
              border-radius: 6px;

              &.completed {
                background-color: var(--primaryWardColor);

                img {
                  width: 18px;
                }
              }

              &.bordered {
                border: 2px solid var(--primaryWardColor);
              }

              &.fill {
                border: 2px solid var(--primaryWardColor);
              }

              &.awaiting-approval {
                background-color: $yellow;
                border-color: $yellow;

                img {
                  width: 18px;
                }
              }

              &.incomplete {
                border: 2px solid $yellow;
              }

              &.stolen {
                background-color: red;
                color: white;
              }

              &.f2f-notavailable {
                background-color: var(--tertiaryGrayColor);
                border: 2px solid var(--tertiaryGrayColor);

                img {
                  width: 18px;
                }
              }
            }
          }

          .content-box {
            h3,.h3 {
              font-weight: 700;
              color: $black-1;
              line-height: 22px;
              min-height: 20px;
              display: flex;
              justify-content: space-between;
              font-size: var(--font-16);
              margin-bottom: 3px;
            }

            p {
              color: var(--tertiaryGrayColor);
              font-size: var(--font-12);
              line-height: 15px;
              margin-bottom: 0px;
              min-height: 15px;
              max-height: 15px;

              .status-text {
                font-weight: 600;
                display: inline-block;
                padding-left: 17px;
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  left: 3px;
                  width: 4px;
                  height: 4px;
                  background: var(--tertiaryGrayColor);
                  border-radius: 3px;
                  top: 6px;
                }
              }
            }

            .days-listing {
              margin-top: 5px;
              display: flex;
              align-items: center;

              li {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
                background: #f4f5f5;
                text-align: center;
                line-height: 24px;
                cursor: pointer;
                color: #000000;
                border-radius: var(--borderRadiusS);
                font-size: var(--font-12);
                margin-right: 5px;

                &.fill {
                  background-color: var(--primaryWardColor);
                  color: #fff;
                }

                &.bordered {
                  border: 2px solid var(--primaryWardColor);
                  color: var(--primaryWardColor);
                }

                &.pending {
                  background-color: $yellow;
                }

                &.stolen {
                  background-color: red;
                  color: white;
                }

              }
            }

          }

          .amount-box {
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--tertiaryGrayColor);
            font-weight: normal;
            margin-left: auto;
            padding-left: 30px;
          }
        }

        @media (max-width:575px) {
          margin-top: 10px;
        }
      }
    }

    .goals-tab-wrapper {
      .goal-filter-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        select {
          width: 150px;
          background-color: $grey-2;
          border: none;
          margin-right: 10px;
          font-size: var(--font-16);
          line-height: 19px;
          font-weight: 600;
          height: auto;
          padding: 10px 12px;
          color: $label-highlight;
          border-radius: var(--borderRadiusM);

          &:focus {
            box-shadow: none;
          }
        }

        .desktop-add-goal-btn {
          @media (max-width:575px) {
            display: none;
          }
        }

        .mobile-add-goal-btn {
          display: none;

          @media (max-width:575px) {
            display: block;
            padding: 10px 12px;
            border-radius: var(--borderRadiusS);
            min-width: auto;

            img {
              width: 19px;
            }
          }
        }

        @media (max-width:575px) {
          position: sticky;
          top: 103px;
          background: #fff;
          z-index: 9;
          padding-top: 29px;
          padding-bottom: 20px;
          margin-bottom: 10px;
        }
      }

      .goals-progress-single {
        margin-bottom: 30px;
        display: flex;

        .goal-avatar {
          min-width: 64px;
          max-width: 64px;
          background: $grey-2;
          min-height: 64px;
          max-height: 64px;
          background-position: center;
          background-size: cover;
          border-radius: 32px;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;

          svg {
            width: 23px;

            @media (max-width:575px) {
              width: 20px;
            }
          }

          @media (max-width: 575px) {
            background-size: 25px;
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
          }

          @media (max-width: 480px) {
            background-size: 20px;
          }
        }

        .goal-progress-bar {
          padding-left: 16px;
          width: 100%;

          h5,.h5 {
            font-size: var(--font-14);
            line-height: 22px;
            color: #000;
            margin-bottom: 6px;
            font-weight: 600;
            min-height: 22px;

            span {
              font-size: var(--font-16);
              line-height: 24px;
              font-weight: normal;
              color: #000;
            }
          }

          .progress {
            height: 8px;
            background-color: #f6f7f7;
            margin-bottom: 5px;

            .progress-bar {
              background: var(--primaryWardColor);
              height: 8px;
              border-radius: 4px 0px 0px 4px;
            }
          }

          .progress-value {
            font-size: var(--font-14);
            font-weight: 500;
            line-height: 16px;
            color: #000;
            max-width: 100%;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .goal-transactions-wrapper {
        margin-top: 100px;

        @media (max-width:575px) {
          margin-top: 50px;
        }
      }

      .transaction-heading {
        font-size: var(--font-16);
        padding-bottom: 10px;
        margin-bottom: 0;
        color: var(--primaryWardColor);
        font-weight: 600;

        @media (max-width:575px) {
          font-size: var(--font-15);
        }
      }

      .month-wrapper {
        .month-name {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 7px 7px;
          margin-bottom: 0px;
          margin-top: 0px;
          line-height: 1.2;
        }

        .transaction-listing {
          .table {
            margin: 0px;

            tbody {
              tr {
                border-bottom: 1px solid #f4f5f5;

                td {
                  border: none;
                  padding: 16px 0px;
                  vertical-align: middle;
                  font-size: var(--font-14);
                  font-weight: 300;

                  h5,.h5 {
                    font-size: var(--font-16);
                    margin-bottom: 0px;
                    font-weight: 300;
                    line-height: 24px;

                    @media (max-width: 575px) {
                      font-size: var(--font-14);
                      line-height: 20px;
                    }
                  }

                  p {
                    font-size: var(--font-12);
                    margin-bottom: 0px;
                    color: var(--tertiaryGrayColor);
                    line-height: 16px;
                    letter-spacing: 0.01em;
                  }

                  .avtar {
                    width: 48px;
                    height: 48px;
                    min-width: 48px;
                    min-height: 48px;
                    background-color: #f4f5f5;
                    border-radius: 24px;
                    overflow: hidden;

                    @media (max-width:575px) {
                      width: 42px;
                      height: 42px;
                      min-width: 42px;
                      min-height: 42px;
                    }
                  }

                  &:last-child {
                    font-size: var(--font-16);
                    line-height: 24px;
                    color: var(--primaryWardColor);
                    font-weight: normal;

                    @media (max-width: 575px) {
                      font-size: var(--font-15);
                      line-height: 20px;
                    }
                  }

                  &:nth-child(2) {
                    font-weight: normal;
                    padding-left: 14px;
                    padding-right: 80px;
                  }

                  &.negative {
                    color: var(--errorColor);
                  }

                  &.positive {
                    color: var(--tertiaryColor);
                  }

                  @media (max-width: 575px) {
                    padding: 10px 0px;
                    vertical-align: top;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//MODAL : CHORE DETAIL
.modal-chore-detail {
  width: 600px !important;
  max-width: 600px !important;
}

.childChoreDetail-modal {

  .modal-heading {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
  }

  .days-listing {
    margin-top: 30px;

    ul {
      padding: 0px;
      margin: 0px;
      display: flex;
      justify-content: space-between;

      li {
        list-style: none;
        display: inline-block;
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
        background: $grey-2;
        text-align: center;
        cursor: pointer;
        color: $black;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 6px 0px;

        .week-name {
          width: 100%;
          line-height: 20px;
        }

        .status-icon {
          img {
            width: 10px;
            display: block;
          }
        }

        &.fill {
          background-color: var(--primaryWardColor);
          color: #fff;
        }

        &.bordered {
          border: 2px solid var(--primaryWardColor);
          color: var(--primaryWardColor);
        }

        &.not-completed {
          background-color: var(--errorColor);
          color: #fff;
        }

        &.f2f-not-available {
          background-color: $grey-3;
          color: #fff;
        }

        &.approval-pending {
          border: 2px solid $yellow;
          color: $yellow;
        }

        &.awaiting-approval {
          background-color: $yellow;
          color: #fff;
        }

        @media (max-width:425px) {
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
        }
      }
    }
  }

  .rewards {
    margin-top: 50px;

    h3,.h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: var(--font-16);
      font-weight: 600;
      margin-bottom: 15px;
      color: var(--primaryWardColor);
      line-height: 24px;
      letter-spacing: -0.02em;

      .amount-input {
        color: var(--tertiaryGrayColor);
        font-weight: 500;
        letter-spacing: 0.3px;
        font-size: var(--font-18);
      }
    }
  }
}


//MODAL - CHILD CARD DETAIL
.childCardDetail-modal {
  .modal-inner {
    margin-top: 30px;
    position: relative;

    .toast-msg {
      background-color: #7C8687;
      color: #fff;
      border-radius: var(--borderRadiusL);
      position: absolute;
      padding: 9px 17px 7px;
      font-size: var(--font-14);
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }

    .card-image {
      text-align: center;
    }

    .card-details {
      margin-top: 50px;

      .card-item {
        display: flex;
        align-items: center;
        padding: 15px 0px;
        border-bottom: 1px solid $grey-2;

        .label {
          color: var(--primaryWardColor);
          font-size: var(--font-16);
          line-height: 24px;
        }

        .number {
          color: $label-highlight;
          font-size: var(--font-16);
          line-height: 24px;
          font-weight: 700;
          margin-left: auto;
          margin-right: 20px;
        }

        .copy {
          img {
            width: 14px;
            cursor: pointer;
          }
        }

        .add-google-pay {
          margin-left: auto;
          font-size: var(--font-13);
          background-color: $black;
          color: #fff;
          padding: 4px 30px;
          border-radius: 4px;
        }
      }
    }

    .card-declaration{
      font-size: var(--font-12);
      text-align: center;
      margin-top: 70px;
    }
  }
}

//MODAL - CHILD ADD GOAL
.childAddGoal-modal {
  .form-box {
    margin-top: 30px;

    .form-group {
      margin-bottom: 53px;

      .amount-group {
        position: relative;

        span.dollar {
          position: absolute;
          top: 8px;
          color: var(--tertiaryGrayColor);

          &.active-dollar {
            color: $label-highlight;
          }
        }

        input {
          padding-left: 10px;
          color: $label-highlight;
        }
      }
    }
  }
}


//PAGE - CHANGE PASSWORD
.page-child-changePassword {
  .dashboard-heading {
    text-align: center;
    display: block !important;
  }

  .routeBack-abs {
    top: 0;

    img {
      margin-top: 0;
    }

    @media (max-width:575px) {
      top: 14px;
    }
  }
}

//MODAL - CHILD CHORE LEGEND
.childChorelegendsInfo {
  h1,.h1 {
    margin-bottom: 40px;
  }

  .legendsBox {
    .singleLegend {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icn {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 3px;

        img {
          width: 19px;
        }
      }

      &:nth-child(1) {
        .icn {
          background-color: var(--primaryWardColor);
        }
      }

      &:nth-child(2) {
        .icn {
          background-color: transparent;
          border: 2px solid var(--primaryWardColor);
        }
      }

      &:nth-child(3) {
        .icn {
          background-color: $yellow;
          // background-color: #f2c94c;
        }
      }

      &:nth-child(4) {
        .icn {
          background-color: transparent;
          border: 2px solid $yellow;
        }
      }

      &:nth-child(5) {
        .icn {
          background-color: var(--tertiaryGrayColor);
        }
      }
    }
  }
}

//PAGE - CHILD PROFILE
.page-child-profile {
  .dashboard-heading {
    display: none !important;
  }
}


//PAGE - CHILD SETTINGS
.page-child-settings {
  .dashboard-heading {
    display: none !important;
  }

  .child-peers-desktop-invite {
    @media (max-width:575px) {
      display: none;
    }
  }
}

.page-child-settings {
  .children-setting-wrapper {
    .spending-tab {
      .single-spending-tab {
        .spending-row {
          h3,.h3 {
            .inputGrp {
              color: var(--tertiaryGrayColor);

              span {
                position: initial;
                float: none;
              }
            }
          }
        }
      }
    }
  }
}

//MODAL- INVITE PEER
.invite-peer-modal {
  h1,.h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 30px;

    label {
      font-weight: 600;
      color: var(--primaryWardColor);
    }

    &.info-content {
      font-size: var(--font-14);
    }
  }

}

//PAGE- EDUCATION LISTING
.page-child-education {
  .dashboard-heading {
    display: block !important;
    text-align: center;

    @media (max-width:575px) {
      font-size: var(--font-18) !important;
      padding-top: 10px;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 9;
    }
  }

  .education-wrapper {
    .top-buttons-group {
      padding-bottom: 31px;
      position: absolute;
      top: -46px;
      left: 0;

      @media (max-width:1024px) {
        top: -26px;
      }

      @media (max-width:575px) {
        padding-top: 0px;
        position: fixed;
        z-index: 9;
        background-color: #fff;
        top: 21px;
        left: 15px;
        padding-bottom: 0;
      }


    }
  }
}



//PAGE - EDUCATION DETAIL
.page-child-education-detail-contentId {
  .dashboard-heading {
    display: none !important;
  }


  .education-dtl-page {
    .header-top {
      padding-top: 10px !important;
      justify-content: flex-start;

      .privacy-shiled {
        margin-left: 20px;
      }
    }
  }
}

//PAGE - SHOPPING
.page-child-shopping {
  .dashboard-heading {
    display: none !important;
  }
}

.child-shop-wrapper {
  .top-buttons-group {
    padding-bottom: 31px;

    @media (max-width:575px) {
      position: sticky;
      z-index: 9;
      background-color: #fff;
      top: 0;
      margin-left: -15px;
      margin-right: -15px;
      padding: 21px 15px 0px;
    }
  }

  .nav-tabs {
    @media (max-width:575px) {
      position: sticky;
      top: 66px;
      background: #fff;
      z-index: 9;
    }
  }

  .tabs-privacy-policy {
    @media (max-width:575px) {
      display: none;
    }
  }

  .noShopData {
    width: 400px;
    margin: 70px auto;

    .thumbIcon {
      width: 164px;
      height: 164px;
      background: #ddd;
      border-radius: 100px;
      margin: 0px auto 83px;
    }

    .noChild-title {
      padding: 0px 50px;
      text-align: center;
      font-size: var(--font-18);
      margin-bottom: 30px;
    }
  }

  .shop-dash-merchants {
    .search-box-row {
      @media (max-width:575px) {
        position: sticky;
        top: 119px;
        background: #fff;
        z-index: 9;
        padding-top: 0px;
      }
    }

    .searchMerchant {
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .inner-search {
        position: relative;
        border-radius: var(--borderRadiusM);
        width: 100%;

        input {
          border: none;
          padding: 16px 16px 16px 48px;
          height: auto;
          border-radius: var(--borderRadiusM);
          background-color: #f4f5f5;
          border: 1px solid transparent;
          transition: all 0.7s;

          &:focus {
            border: 1px solid var(--primaryWardColor);
          }

          @media (max-width:575px) {
            border-radius: var(--borderRadiusM);
          }
        }

        img.searchIcn {
          position: absolute;
          top: 21px;
          left: 16px;
        }

        img.filterIcon {
          position: absolute;
          top: 18px;
          cursor: pointer;
          right: 16px;
          width: 21px;
        }

        img.infoIcn {
          position: absolute;
          top: 18px;
          cursor: pointer;
          right: 16px;
        }
      }

      .closeFilter {
        cursor: pointer;
        font-weight: 500;
        font-size: var(--font-14);
        color: var(--primaryWardColor);
        transition: all 0.5s;
      }

      @media (max-width:575px) {
        margin-bottom: 0px;
      }
    }
  }

  .shop-all-retailers {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;

    .retailer-single {
      display: flex;
      align-items: center;
      box-shadow: 0px 3px 8px #dedede;
      width: 48%;
      border-radius: var(--borderRadiusM);
      padding: 19px 16px;
      cursor: pointer;
      margin-bottom: 30px;

      img {
        margin-right: 18px;
        width: 48px;
        text-align: center;
        background-size: 30px;
        overflow: hidden;
      }

      .retailer-name {
        h4,.h4 {
          font-size: var(--font-16);
          line-height: 24px;
          color: #2c3333;
          width: 100%;
          margin: 0px;
          margin-bottom: 0px;
          overflow: hidden;
          font-weight: 700;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }

  .shop-limited-retailers {
    .tiles-cards-group {
      .dashboard-children-card {
        .singleRetailer {
          display: flex;
          // padding: 15px 15px;
          // background: #ffffff;
          // box-shadow: 0px 4px 8px rgba(209, 222, 222, 0.41);
          // border-radius: 16px;
          // margin-bottom: 8px;
          position: relative;

          .tile-background {
            width: 100%;
            height: 130px;
            background-size: 100% 100%;
            filter: drop-shadow(1px 2px 4px rgb(222, 222, 222));
            background-repeat: no-repeat;
            background-origin: content-box;
          }

          .card-inner {
            padding: 16px 16px 20px;
            width: 100%;
            display: block;

            .merchant-details {
              display: flex;
              span {
                margin-right: 40px;
                width: 60px;
                min-width: 60px;
                height: 32px;
                min-height: 32px;
                line-height: 12px;
                text-align: center;
                background-size: 30px;
                background-repeat: no-repeat;
                background-position: center;
                overflow:initial;
                display: flex;
                align-items:flex-start;
  
                img {
                  width: 48px;
                  min-width: 48px;
  
                  @media (max-width:575px) {
                    width: 42px;
                    min-width: 42px;
                  }
                }
              }

              a{
                h4{
                  font-size: var(--font-16);
                  line-height: 24px;
                  color: #2c3333;
                  width: 100%;
                  margin: 0px;
                  margin-bottom: 0px;
                  overflow: hidden;
                  font-weight: 700;
                }
              }

              @media (max-width:575px) {
                // width: 42px;
                // min-width: 42px;
                // height: 42px;
                // min-height: 42px;
              }
            }

            .tile-content {
              width: 100%;

              h4,.h4 {
                font-size: var(--font-16);
                line-height: 24px;
                color: #2c3333;
                width: 100%;
                margin: 0px;
                margin-bottom: 0px;
                overflow: hidden;
                font-weight: 700;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }

            .tile-content {
              width: 100%;

              .limits-group {
                display: flex;
                align-items:flex-end;

                label{
                  width: 60px;
                  min-width: 60px;
                  margin-right: 40px;
                  font-size: 12px !important;
                  margin-bottom: 0px;
                  line-height: 21px;
                }

                .limit-box {
                  text-align: right;
                  margin-right: 20px;
                  min-width: 55px;
                  max-width: 55px;
                  border-bottom: 1px solid var(--primaryWardColor);

                  .limit {
                    color: #2c3333;
                    font-size: 14px;
                    text-align: right;
                    position: relative;

                    // &::before {
                    //   content: '$';
                    //   color: #2c3333;
                    //   position: absolute;
                    //   left:0;
                    // }
                  }

                  p {
                    font-size: var(--font-12);
                    color: var(--tertiaryGrayColor);
                    margin-bottom: 2px;
                  }
                }

                &.bottom{
                  .limit-box{
                    border-bottom: none;

                    .limit{
                      color: var(--tertiaryGrayColor);;

                      &::before {
                        color: var(--tertiaryGrayColor);;
                      }
                    }
                  }
                  
                }

              }
            }
          }
        }

        &.no-limits-card{
          .singleRetailer{
            .tile-background{
              filter: drop-shadow(1px 2px 4px rgb(222, 222, 222));
            }
            .card-inner{
              padding:13px 16px;

              .merchant-details{
                span{
                  height:auto;
                  min-height: auto;
                }
                
              }
            }
          }
        }

        @media (max-width:480px) {
          margin-bottom: 15px !important;
        }
      }
    }

    @media (max-width:575px){
      margin-top: 25px;
    }
  }

  .shop-limited-charities {
    .tiles-cards-group {
      .dashboard-children-card {
        .singleRetailer {
          display: flex;
          position: relative;

          .tile-background {
            width: 100%;
            height: 130px;
            background-size: 100% 100%;
            filter: drop-shadow(1px 2px 4px rgb(222, 222, 222));
            background-repeat: no-repeat;
            background-origin: content-box;
          }

          .card-inner {
            padding: 16px 16px 20px;
            width: 100%;
            display: block;

            .merchant-details{
              display: flex;
              span{
                margin-right: 0;
                width: 60px;
                min-width: 60px;
                height: 32px;
                min-height: 32px;
                line-height: 12px;
                text-align: center;
                background-size: 30px;
                background-repeat: no-repeat;
                background-position: center;
                overflow: initial;
                display: flex;
                align-items:flex-start;

                img{
                  width: 40px;
                  min-width: 40px;
                }
              }

              h4{
                font-size: var(--font-14);
                line-height: 20px;
                color: #2c3333;
                width: 100%;
                margin: 0px auto 15px;
                overflow: hidden;
                font-weight: 700;
                text-overflow: ellipsis;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                display: flex;

                img.info{
                  margin-left: auto;
                  width: 22px;
                  height: 22px;
                }
              }
            }

            .tile-content {
              width: 100%;

              .limits-group {
                display: flex;
                align-items:flex-end;

                label{
                  width: 60px;
                  min-width: 60px;
                  margin-right: 40px;
                  font-size: 12px !important;
                  margin-bottom: 0px;
                  line-height: 21px;
                }

                .limit-box {
                  text-align: right;
                  margin-right: 20px;
                  min-width: 60px;
                  border-bottom: 1px solid var(--primaryWardColor);

                  .limit {
                    color: #2c3333;
                    font-size: 12px;
                    text-align: right;
                    position: relative;

                    // &::before {
                    //   content: '$';
                    //   color: #2c3333;
                    //   position: absolute;
                    //   left:0;
                    // }
                  }

                  p {
                    font-size: var(--font-12);
                    color: var(--tertiaryGrayColor);
                    margin-bottom: 2px;
                  }
                }

                &:last-child{
                  .limit-box{
                    border-bottom: none;

                    .limit{
                      color: var(--tertiaryGrayColor);;

                      &::before {
                        color: var(--tertiaryGrayColor);;
                      }
                    }
                  }
                  
                }

              }
            }

            @media (max-width:1200px) {
              // padding:16px 16px 25px;
            }

            @media (max-width:425px) {
              // padding:24px 16px 25px;
            }

            @media (max-width:375px) {
              // padding:21px 16px 25px;
            }
          }

          // @media (max-width:575px) {
          //   box-shadow: 0px 4px 12px #d1dedead;
          //   margin-bottom: 18px;
          //   padding: 15px 16px 24px !important;
          //   border-radius: 41px;
          //   align-items:flex-start;
          // }
        }

        @media (max-width:480px) {
          margin-bottom: 15px !important;
        }
      }
    }
  }
}


//PAGE - WALLET/SAVINGS/GOALS

.page-child-digital-wallet,
.page-child-digital-saving,
.page-child-digital-goals {
  .dashboard-heading {
    display: block !important;
    text-align: center;

    @media (max-width:575px) {
      top: 0px;
      position: sticky;
      background-color: #fff;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .top-transfer-button {
    color: var(--primaryWardColor);
    position: absolute;
    font-size: var(--font-16);
    line-height: 24px;
    top: 0;
    cursor: pointer;
    right: 0;

    @media (max-width:575px) {
      top: 18px;
      position: fixed;
      right: 15px;
      z-index: 9;
    }
  }

  .routeBack-abs {
    top: 0px;

    @media (max-width:575px) {
      position: fixed;
      left: 15px;
      top: 20px;
      z-index: 9;

      img {
        margin-top: 0px;
      }
    }
  }

  .digital-wallet-wallet {
    .wallet-amount {
      text-align: center;
      padding-bottom: 30px;

      .amount {
        font-size: var(--font-40);
        line-height: 56px;
        letter-spacing: -0.03em;
        color: #424242;
        font-weight: 100;

        @media (max-width:575px) {
          font-size: var(--font-35);
        }
      }

      .total-balance {
        font-size: var(--font-13);
        line-height: 16px;
        letter-spacing: 0.01em;
        cursor: pointer;
        color: var(--tertiaryGrayColor);
      }

      @media (max-width:575px) {
        position: sticky;
        top: 69px;
        background-color: #fff;
        z-index: 9;
      }
    }

    .transaction-heading {
      font-size: var(--font-16);
      padding-bottom: 10px;
      margin-bottom: 0;
      color: var(--primaryWardColor);
      font-weight: 600;

      @media (max-width:575px) {
        font-size: var(--font-15);
        position: sticky;
        top: 171px;
        z-index: 9;
        background-color: #fff;
        padding: 0px 15px 10px;
      }
    }

    .month-wrapper {
      .month-name {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 7px 7px;
        margin-bottom: 0px;
        margin-top: 0px;
        line-height: 1.2;

        @media (max-width:575px) {
          padding: 7px 15px;
        }
      }

      .transaction-listing {
        .table {
          margin: 0px;

          tbody {
            tr {
              border-bottom: 1px solid #f4f5f5;

              td {
                border: none;
                padding: 16px 0px;
                vertical-align: middle;
                font-size: var(--font-14);
                font-weight: 300;

                h5,.h5 {
                  font-size: var(--font-16);
                  margin-bottom: 0px;
                  font-weight: 300;
                  line-height: 24px;

                  @media (max-width: 575px) {
                    font-size: var(--font-14);
                    line-height: 20px;
                  }
                }

                p {
                  font-size: var(--font-12);
                  margin-bottom: 0px;
                  color: var(--tertiaryGrayColor);
                  line-height: 16px;
                  letter-spacing: 0.01em;
                }

                .avtar {
                  width: 48px;
                  height: 48px;
                  min-width: 48px;
                  min-height: 48px;
                  background-color: #f4f5f5;
                  border-radius: 24px;
                  overflow: hidden;

                  @media (max-width:575px) {
                    width: 42px;
                    height: 42px;
                    min-width: 42px;
                    min-height: 42px;
                  }
                }

                &:last-child {
                  font-size: var(--font-16);
                  line-height: 24px;
                  color: var(--primaryWardColor);
                  font-weight: normal;

                  @media (max-width: 575px) {
                    font-size: var(--font-15);
                    line-height: 20px;
                  }
                }

                &:nth-child(2) {
                  font-weight: normal;
                  padding-left: 14px;
                  padding-right: 80px;
                }

                &.negative {
                  color: var(--errorColor);
                }

                &.positive {
                  color: var(--tertiaryColor);
                }

                @media (max-width: 575px) {
                  padding: 15px 0px;
                  vertical-align: top;
                }
              }
            }
          }
        }

        @media (max-width:575px) {
          padding: 0px 15px;
        }
      }
    }

    @media (max-width:575px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}


//MODAL - VIEW GOAL
.childViewGoal-modal {
  .goal-shield {
    margin-top: 31px;
    text-align: center;

    .shield-icon {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $grey-2;
      border-radius: 50px;
      border: 2px solid var(--primaryWardColor);
      margin: 0px auto 30px;

      img,
      svg {
        width: 60px;
      }
    }

    p {
      color: var(--primaryWardColor);
      font-size: var(--font-16);
      font-weight: 24px;
      font-weight: 600;
    }
  }

  .goal-progress-bar {
    .goal-amount {
      font-size: var(--font-16);
      line-height: 24px;
      color: #000;
      margin-bottom: 6px;
      font-weight: normal;
      min-height: 24px;
      text-align: right;
    }

    .progress {
      height: 8px;
      background-color: #f6f7f7;
      margin-bottom: 5px;

      .progress-bar {
        background: var(--primaryWardColor);
        height: 8px;
        border-radius: 4px 0px 0px 4px;
      }
    }
  }

  .goal-total {
    margin-top: 50px;

    .price-list,
    .price-total {
      padding: 5px 0px;
      display: flex;
      justify-content: space-between;

      .title {
        color: $black;
        font-size: var(--font-16);
        line-height: 24px;
        font-weight: 600;
      }

      .amount {
        color: $black;
        font-size: var(--font-16);
        line-height: 24px;
      }
    }

    .price-total {
      border-top: 1px solid $black;
      margin-top: 5px;
      padding-top: 10px;

      .amount {
        font-weight: 600;
      }
    }
  }

  .bottom-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .linkBtn {
      color: var(--primaryWardColor);
      font-size: var(--font-16);

      @media (max-width:575px) {
        position: absolute;
        top: 22px;
        z-index: 999;
        left: 20px;
        font-size: var(--font-15);
      }
    }

    @media (max-width:575px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }


}


//MODAL - PAY PEER
.payPeerModal-wrapper {
  h1.modal-heading,.h1.modal-heading {
    margin-bottom: 31px;
  }

  .form-box {
    .form-group {
      label {
        color: var(--primaryColor);
      }

      .fromToAccountGrp {
        margin-bottom: 32px;

        .fromAccount {
          margin-bottom: 32px;

          label{
            color: var(--tertiaryGrayColor);
          }

          select{
            color: var(--tertiaryGrayColor);
          }

          @media (max-width: 480px) {
            margin-bottom: 58px;
          }
        }

        .fromAccount,
        .toAccount {
          width: 100%;

          label {
            display: block;
          }
        }

        select {
          font-weight: 600;
          background-color: #f4f5f5;
          border: none;
          margin-right: 10px;
          font-size: var(--font-16);
          line-height: 20px;
          height: 36px;
          color: $label-highlight;
          border-radius: var(--borderRadiusM);

          &:focus {
            box-shadow: none;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width: 575px) {
            font-size: var(--font-15);
            height: 62px;
            background: transparent;
            border: 1px solid $grey-3;
            color: $label-highlight;
            border-radius: var(--borderRadiusS);

            &::placeholder {
              color: $grey-3;
            }
          }
        }
      }

      .transferMemo {
        margin-bottom: 32px;

        textarea {
          border-color: $label-highlight;
          font-size: var(--font-13);

          &:focus {
            box-shadow: none;
          }

          @media (max-width:575px) {
            height: 48px;
            border: none;
            border-radius: 0;
            resize: none;
            padding: 12px 0.75rem 12px 0;
            overflow: hidden;
            font-size: var(--font-15);
          }
        }
      }

      .recurringBtnGrp {
        display: flex;
        justify-content: space-between;

        button {
          background-color: transparent;
          color: var(--tertiaryGrayColor);
          font-size: var(--font-16);
          line-height: 24px;
          border: none;
          padding: 8px 15px;
          margin-right: 5px;
          font-weight: 400;
          border-radius: var(--borderRadiusS);

          &.active {
            background-color: var(--primaryWardColor);
            color: #fdfcfb;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width:575px) {
            font-size: var(--font-14);
            padding: 6px 20px;
          }

          @media (max-width:390px) {
            padding: 6px 10px;
          }
        }
      }

      .once {
        margin-top: 30px;

        .react-datepicker {
          width: 100%;
          border: none;

          .react-datepicker__navigation--previous {
            right: 50px;
            left: auto;
          }

          .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
              background-color: #fff;
              border: none;

              .react-datepicker__current-month {
                margin-bottom: 20px;
              }

              .react-datepicker__day-names {
                display: flex;
                justify-content: space-around;

                .react-datepicker__day-name {
                  font-weight: 300;
                  font-size: var(--font-12);
                  color: var(--tertiaryGrayColor);
                  letter-spacing: 0.1em;
                }
              }
            }

            .react-datepicker__month {
              background-color: #fff;
              margin: 0px;

              .react-datepicker__week {
                display: flex;
                justify-content: space-around;
                font-size: var(--font-16);

                .react-datepicker__day {
                  margin-bottom: 10px;
                  font-family: 'GothamBook';
                }

                // .react-datepicker__day--today {
                //   background-color: var(--primaryWardColor);
                //   border-radius: 100%;
                //   color: #fff;
                // }

                .react-datepicker__day--keyboard-selected {
                  background-color: transparent;
                  color: #000;
                }

                .react-datepicker__day--selected {
                  background-color: var(--primaryWardColor);
                  border-radius: 100%;
                  color: #fff;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .weeksName {
        margin-top: 30px;

        ul {
          padding: 0px;
          margin: 0px;
          display: flex;
          justify-content: space-between;

          li {
            list-style: none;
            display: inline-block;
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-width: 50px;
            background: #f4f5f5;
            text-align: center;
            line-height: 50px;
            margin-right: 10px;
            color: $black;
            font-weight: 500;
            border-radius: 5px;
            cursor: pointer;

            &.active {
              background-color: var(--primaryWardColor);
              color: #fff;
            }

            &:last-child {
              margin-right: 0px;
            }

            @media (max-width:425px) {
              width: 40px;
              min-width: 40px;
              height: 40px;
              min-width: 40px;
              line-height: 40px;
            }

            @media (max-width:375px) {
              margin-right: 7px;
            }
          }
        }
      }

      .monthly{
        .monthlySelection {
          display: flex;
          column-gap: 20px;
    
          label {
            font-weight: 700;
          }
    
          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);
    
            &:last-child {
              margin-right: 0px;
            }
    
            &:focus {
              box-shadow: none;
            }
          }
        }
    
        .recurrence-selection {
          margin-top: 45px;
          margin-bottom: 45px;
    
          label {
            font-weight: 700;
          }
    
          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);
    
            &:focus {
              box-shadow: none;
            }
          }
        }
    
        .recurrence-dayofMonth {
          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);
    
            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      .careGiver {
        margin-bottom: 44px;

        label {
          margin-bottom: 18px;
        }

        .parentsList {
          .parentSingle {
            margin-right: 10px;
            float: left;

            li {
              width: 64px;
              height: 64px;
              background: #f4f5f5;
              list-style: none;
              text-align: center;
              border-radius: 32px;
              display: inline-block;
              margin-right: 16px;
              cursor: pointer;
              position: relative;

              .image-content {
                border-radius: 32px;
                overflow: hidden;
              }

              p {
                font-size: var(--font-12);
                margin-bottom: 0px;
                line-height: 16px;
                color: #4c5562;
                opacity: 0.7;
                letter-spacing: 0.01em;
                margin-top: 3px;
              }

              img.check {
                display: none;
              }

              &:last-child {
                margin-right: 0px;
              }

              &.active {
                img.check {
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  display: initial;
                }
              }
            }
          }

          .addNewParent {
            float: left;
            width: 64px;
            height: 64px;
            background: #f4f5f5;
            list-style: none;
            text-align: center;
            line-height: 60px;
            border-radius: 32px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }

      &.amount {
        margin-bottom: 32px;

        .input-dollar-group {
          position: relative;

          input {
            font-size: var(--font-40) !important;
            line-height: var(--font-56);
            padding: 0px 0px 0px var(--font-24);
            color: $label-highlight;
            height: auto;
            border-color: #97b0b036;
            //font-family: "GothamXLight";

            @media (max-width:575px) {
              font-family: 'GothamXLight';
              height: 65px;
            }
          }

          input::placeholder {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-40) !important;
            line-height: var(--font-56);;
            font-weight: 100;
          }

          .dollar {
            font-size: var(--font-40);
            // line-height: 56px;
            //font-family: "GothamXLight";
            color: var(--tertiaryGrayColor);
            position: absolute;
            top: 9px;
            font-weight: 100;
            line-height: 37.5px;

            &.dollar-active {
              color: $label-highlight;
            }

            @media (max-width:575px) {
              font-family: 'GothamXLight';
              top: 13px;
            }
          }
        }

        @media (max-width: 480px) {
          margin-bottom: 43px;
        }
      }

      @media (max-width:575px) {
        margin-bottom: 58px;
      }
    }
  }

  .bottom-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .linkBtn {
      color: var(--primaryWardColor);
      font-size: var(--font-16);

      @media (max-width:575px) {
        font-size: var(--font-15);
        position: absolute;
        top: 24px;
        right: 20px;
      }
    }

    @media (max-width:575px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .transfer-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryWardColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;
      margin-bottom: 120px;

      h3,.h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryWardColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        z-index: 9;
        left: 0px;
        padding: 10px 15px 30px;
      }
    }
  }
}

//MODAL - REQUEST FUNDS
.child-request-fund {
  h1.modal-heading,.h1.modal-heading {
    margin-bottom: 31px;
  }

  .form-box {
    .form-group {
      margin-bottom: 43px;

      label {
        font-size: var(--font-16);
        line-height: 24px;
        color: var(--primaryWardColor);
        font-weight: 600;
      }

      .input-dollar-group {
        position: relative;

        input {
          font-size: var(--font-40) !important;
          line-height: var(--font-56);;
          padding: 0px 0px 0px var(--font-24);
          color: $label-highlight;
          height: auto;
          border-color: $grey-4;
          font-family: 'GothamXLight';
          text-align: left !important;

          @media (max-width:575px) {

            height: 65px;
          }
        }

        input::placeholder {
          color: $grey-4;
          font-size: var(--font-40) !important;
          line-height: var(--font-56);;
          font-weight: 100;
        }

        .dollar {
          font-size: var(--font-40);
          line-height: 56px;
          font-family: "GothamXLight";
          color: $grey-4;
          position: absolute;
          top: 9px;
          font-weight: 100;
          line-height: 38px;

          &.dollar-active {
            color: $label-highlight;
          }

          @media (max-width:575px) {
            font-family: 'GothamXLight';
            top: 13px;
          }
        }
      }

      &.to-be-used-group {
        input {
          border: none;
        }
      }

      .parentListing {
        margin-top: 10px;

        li {
          list-style: none;
          display: inline-block;
          width: 64px;
          text-align: center;
          margin-right: 12px;

          &.active {

            img {
              border: 2px solid var(--primaryWardColor);
              border-radius: 100%;
            }

          }

          img {
            width: 64px;
          }

          p {
            color: $grey-4;
            margin: 8px auto 0px;
          }
        }
      }
    }
  }
}

//PAGE - CHILD PAY
.page-child-pay {
  .dashboard-heading {
    display: block !important;
    text-align: center;

    @media (max-width:575px) {
      font-size: var(--font-18) !important;
      padding-top: 10px;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 9;
    }
  }

  .child-pay {
    .top-buttons-group {
      position: absolute;
      top: 0;
      left: 0;

      @media (max-width:575px) {
        padding-top: 12px;
        position: fixed;
        z-index: 9;
        background-color: #fff;
        top: 0;
        left: 15px;
        padding-bottom: 0;
      }
    }

    .top-info {
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width:575px) {
        padding-top: 12px;
        position: fixed;
        z-index: 9;
        background-color: #fff;
        top: 0;
        right: 15px;
      }
    }

    .pay-amount {
      text-align: center;
      padding-bottom: 30px;

      .amount {
        font-size: var(--font-40);
        line-height: var(--font-56);
        letter-spacing: -0.03em;
        color: #424242;
        font-weight: 100;

        @media (max-width:575px) {
          font-size: var(--font-35);
        }
      }

      .total-balance {
        font-size: var(--font-13);
        line-height: 16px;
        letter-spacing: 0.01em;
        cursor: pointer;
        color: var(--tertiaryGrayColor);
      }

      @media (max-width:575px) {
        position: sticky;
        top: 60px;
        background-color: #fff;
        z-index: 9;
      }
    }

    .otp-wrapper {
      text-align: center;

      h3,.h3 {
        font-size: var(--font-16);
        line-height: 24px;
        color: var(--primaryWardColor);
        font-weight: 700;
        margin-top: 20px;
      }

      p {
        font-size: var(--font-16);
        line-height: 24px;
        color: var(--primaryWardColor);
        font-weight: 700;
        margin-top: 70px;
        margin-bottom: 20px;
      }

      .otp-input-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 113px;

        input {
          margin-right: 10px;
          text-align: center;
          font-weight: 400;
          font-size: var(--font-35);
          width: 72px !important;
          height: 72px !important;
          border-radius: var(--borderRadiusM);
          background: #f4f5f5;
          border: 2px solid transparent;
          color: var(--primaryWardColor);

          &:focus,
          &:active {
            box-shadow: none;
            outline: none;
            border: 2px solid var(--primaryWardColor);
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width: 575px) {
            width: 48px !important;
            height: 48px !important;
            min-width: 48px !important;
            min-height: 48px !important;
            margin-right: 10px !important;
            font-size: var(--font-30);
          }
        }
      }
    }
  }
}

//MODAL - DONATE CHARITY
.charityDonate {
  h3,.h3 {
    font-size: var(--font-16);
    color: var(--primaryWardColor);
    line-height: 24px;
    margin-bottom: 25px;
    font-weight: 700;
    text-align: center;
  }

  .charity-info{
    p{
      font-size: var(--font-14);
      margin: 50px auto;
    }
  }

  .buttons-group {
    input {
      border: 1px solid $grey-3;
      font-size: var(--font-29) !important;
      font-weight: 300;
      margin-bottom: 15px;
      padding: 18px 15px;
      height: auto;
      border-radius: var(--borderRadiusM);
      font-family: 'GothamXLight';

      &.active {
        background-color: $child-investment-bg;
        border-color: var(--primaryWardColor);
        color: var(--primaryWardColor);
      }
    }
  }

  .custom-input-box {
    margin-top: 50px;
    margin-bottom: 50px;

    .dollar-input {
      position: relative;

      span.dollar {
        font-size: var(--font-29);
        color: var(--tertiaryGrayColor);
        position: absolute;
        top: 0px;
        line-height: 48px;
        display: none;

        &.dollar-active {
          color: $label-highlight;
        }

        @media (max-width:575px) {
          font-size: var(--font-25);
          top: 9px;
          line-height: 25px;
        }
      }

      input {
        font-size: var(--font-30);
        color: $label-highlight;
        height: auto;
        text-align: left !important;

        &::placeholder {
          font-size: var(--font-29);
          color: var(--tertiaryGrayColor);
          height: auto;

          @media (max-width:575px) {
            font-size: var(--font-25) !important;
            top: 8px;
          }
        }

        @media (max-width:575px) {
          font-size: var(--font-25) !important;
        }
      }

      &.valueEntered {
        span.dollar {
          display: initial;
        }

        input {
          padding-left: 16px;
        }
      }
    }

  }

  .donation-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      background: #fde2cd;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryWardColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          background: #f27405;
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;

      h3,.h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryWardColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 0px;
        padding-top: 10px;
        z-index: 9;
        padding-bottom: 10px;
        left: 15px;
      }
    }
  }
}


//MODAL - CHILD FROZEN CARD ALERT
.childFrozenCard {}