.firefox{
    .btn-gradient, .btn-gradient-outline,
    .btn-gray, .btn-gray-danger, .btn-white{
      font-weight: 600;
    }
    .form-group{
      label{
        font-weight: 600;
      }
    }
    .regoSignup{
      .confirmPhoneNumWrapper{
        .confirmNum-InputsGroups{
          input{
            font-weight: 600;
          }
        }
      }
    }
    .schTransferDetails{
      .detailsSingle{
        .dtlLabel{
          font-weight: 700;
        }
      }
    }
    .dashboard-tile{
      .dashboard-tile-container{
        .dashboard-tile-content{
          .tile-spent.chore{
            min-height: 16px !important;
            max-height: 16px !important;
          }
        }
      }
    }
    .child-detail-wrapper{
      .child-detail-breadcrumb{
        .left-arrrow{
          font-weight: 600;
        }
      }
      .goals-wrapper{
        .goals-progress-container{
          .goals-progress-single{
            .goal-progress-bar{
              .progress-value{
                font-weight: 600;
              }
            }
          }
        }
      }
      .chores-blank-wrapper{
        .noChild-addNewOne{
          .noChild-title{
            font-weight: 600;
          }
        }
      }
    }
    .desktopText{
      font-weight: 700 !important;
    }
    .children-setting-wrapper{
      .address-tab{
        .addNewLink{
          font-weight: 600;
        }
      }
      .spending-tab{
        .single-spending-tab{
          .spending-row{
            h3{
              font-weight: 600;
            }
          }
        }
      }
      .relation-tab{
        .addNewLink{
          font-weight: 600;
        }
        .peers{
          .request-single{
            .childName{
              h3{
                font-weight: 600;
              }
            }
          }
        }
      }
      .peers-tab{
        .requests{
          .request-single{
            .actionBtn{
              button{
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .child-detail-wrapper{
      .displayChart{
        .chartFrame{
          select{
            width:140px;
            font-weight: 600;
          }
        }
      }
    }
    .addNewAddress{
      .defaultAddress{
        h3{
          font-weight: 600;
        }
      }
    }
    .familyInvitation-tile{
      .invitationTile-wrapper{
        .invitationTile-box{
          h3{
            font-weight: 600;
          }
        }
      }
    }
    .inviteChild{
      .form-box{
        .form-group{
          .genderGrp{
            button{
              font-weight: 600;
            }
          }
        }
      }
    }
    .addChildRelation{
      .relationsItem{
        .childRelationSingle{
          .parentName{
            font-weight: 600;
          }
        }
      }
    }
    .goBackInner{
      font-weight: 600;
    }
    .timelines-listing-wrapper{
      .selection-filter{
        select{
          font-weight: 600;
        }
      }
    }
    .recentTran-wrapper{
      .singlePendingTransaction{
        table{
          thead{
            tr{
              th{
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .wallet-page{
      .topLinks{
        span.addFundLink, .transferBtn{
          font-weight: 600;
        }

      }
    }
    .addFundModal-wrapper, .transferModal-wrapper{
      .form-box{
        .form-group{
          .recurringBtnGrp{
            button{
              font-weight: 600;
            }
          }
        }
      }
    }

    .education-wrapper{
      .educationBack{
        span{
          font-weight: 600;
        }
        a{
          font-weight: 600;
        }
      }
    }

    .education-dtl-page{
      .header-top{
        .left-redirect{
          font-weight: 600;
        }
      }
    }

    .shop-wrapper{
      .shop-dash-merchants{
        .approvedRetailers{
          h3{
            font-weight: 600;
          }
          .singleRetailer{
            h4{
              font-weight: 600;
            }
          }
        }
        .additionalRetailers, .additionalCharities{
          .singleRetailer{
            h4{
              font-weight: 600;
            }
          }
        }
      }
    }

    .editProfileWrapper{
      .changePassPin{
        a{
          font-weight: 600;
        }
      }
    }

    .changePassword-wrapper, .changePin-wrapper{
      .goBack{
        span{
          font-weight: 600;
        }
      }
    }
    .changePin-wrapper{
      .subHeading{
        font-weight: 300;
      }
    }

    .parent-setting-wrapper{
      .parentSetting-notifytab{
        .single-spending-tab{
          .spending-row{
            h3{
              font-weight: 600;
            }
          }
        }
      }
      .parentSetting-legaltab{
        #accordion{
          .card{
            .card-header{
              h5{
                button{
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }

  }